import useShallowEqualSelector from './useShallowEqualSelector';
import Const from 'constant/Const';

function useAuthority(args) {
  // FIXME(준호): relationType 기본값으로 internal 로 하는것이 적합?
  const { isHospitalAdmin, relationType, accessLevel } =
    useShallowEqualSelector((state) => ({
      isHospitalAdmin: state.authReducer.user?.isHospitalAdmin ?? false,
      relationType:
        state.authReducer.user?.relationType ??
        Const.USER_RELATION_TYPE.INTERNAL,
      accessLevel:
        state.authReducer.user?.accessLevel ??
        Const.ACCESS_LEVEL.READ_ONLY.value,
    }));

  return {
    // XXX: 병원 어드민에 인터널 병원 사용자가 로그인한 경우, read only?
    isReadOnly: isHospitalAdmin
      ? false
      : accessLevel === Const.ACCESS_LEVEL.READ_ONLY.value,
    isHospitalAdmin,
    relationType,
  };
}

export default useAuthority;
