import React from 'react';
import { useIntl } from 'react-intl';
import AlertDialog from './AlertDialog';

// TODO: 준호 - API 에러 구조 변경에 따라 재수정 필요.
function AlertNetworkDialog(props) {
  const intl = useIntl();

  const { open, params, callback, onClose } = props;
  const { errorInfo } = params;

  const message =
    typeof errorInfo.error === 'object'
      ? Object.values(errorInfo.error)
          .reduce((acc, cur) => {
            return [...acc, ...cur];
          }, [])
          .join('\n')
      : intl.formatMessage({
          id: '99-AlertNetworkDialog-AlertDialog-message-01',
          description: 'API 실패 응답 기본 안내',
          defaultMessage:
            '서버에 연결할 수 없습니다. 네트워크 상태를 확인해주세요.',
        });

  return (
    <AlertDialog
      open={open}
      params={{
        title: intl.formatMessage({
          id: '99-AlertNetworkDialog-AlertDialog-title-01',
          description: 'API 실패 응답 기본 안내 시 제공하는 고정된 제목',
          defaultMessage: '잘못된 요청입니다.',
        }),
        message,
      }}
      callback={callback}
      onClose={onClose}
      zIndex={13}
    />
  );
}

export default AlertNetworkDialog;
