import AlertDialog from './AlertDialog';
import AlertTimerDialog from './AlertTimerDialog';
import ConfirmDialog from './ConfirmDialog';
// import ForgotPasswordDialog from './ForgotPasswordDialog';
import ForgotPasswordDialogContainer from 'redux/container/dialog/ForgotPasswordDialogContainer';
import ReturnPatchDialog from './ReturnPatchDialog';
// import UserManagementDialog from './UserManagementDialog';
import UserManagementDialogContainer from 'redux/container/dialog/UserManagementDialogContainer';
import NewDoctorDialogContainer from 'redux/container/dialog/NewDoctorDialogContainer';
import AddNewTestDialogContainer from 'redux/container/dialog/AddNewTestDialogContainer';
// import UploadDataDialog from './UploadDataDialog';
import UploadDataDialogContainer from 'redux/container/dialog/UploadDataDialogContainer';
// import PrintReportDialog from './PrintReportDialog';
// import PrintReportDialogContainer from 'redux/container/dialog/PrintReportDialogContainer';
import AlertExpireDialogContainer from 'redux/container/dialog/AlertExpireDialogContainer';
import AlertNetworkDialog from './AlertNetworkDialog';
import ConfirmDoctorDialog from './ConfirmDoctorDialog';
import FinalConfirmReportDialogContainer from 'redux/container/dialog/FinalConfirmReportDialogContainer';
import NoticeDialog from './NoticeDialog';

const Dialog = {
  AlertDialog: {
    Name: 'AlertDialog',
    Description: 'AlertDialog description',
    Component: AlertDialog,
  },
  AlertTimerDialog: {
    Name: 'AlertTimerDialog',
    Description: 'AlertTimerDialog description',
    Component: AlertTimerDialog,
  },
  ConfirmDialog: {
    Name: 'ConfirmDialog',
    Description: 'ConfirmDialog description',
    Component: ConfirmDialog,
  },
  ForgotPasswordDialog: {
    Name: 'ForgotPasswordDialog',
    Description: 'ForgotPasswordDialog description',
    Component: ForgotPasswordDialogContainer,
  },
  ReturnPatchDialog: {
    Name: 'ReturnPatchDialog',
    Description: 'ReturnPatchDialog description',
    Component: ReturnPatchDialog,
  },
  UserManagementDialog: {
    Name: 'UserManagementDialog',
    Description: 'UserManagementDialog description',
    Component: UserManagementDialogContainer,
  },
  NewDoctorDialog: {
    Name: 'NewDoctorDialog',
    Description: 'NewDoctorDialog description',
    Component: NewDoctorDialogContainer,
  },
  AddNewTestDialog: {
    Name: 'AddNewTestDialog',
    Description: 'AddNewTestDialog description',
    Component: AddNewTestDialogContainer,
  },
  UploadDataDialog: {
    Name: 'UploadDataDialog',
    Description: 'UploadDataDialog description',
    Component: UploadDataDialogContainer,
  },
  // PrintReportDialog: {
  //   Name: 'PrintReportDialog',
  //   Description: 'PrintReportDialog description',
  //   Component: PrintReportDialogContainer,
  // },
  AlertExpireDialog: {
    Name: 'AlertExpireDialog',
    Description: 'AlertExpireDialog description',
    Component: AlertExpireDialogContainer,
  },
  AlertNetworkDialog: {
    Name: 'AlertNetworkDialog',
    Description: 'AlertNetworkDialog description',
    Component: AlertNetworkDialog,
  },
  ConfirmDoctorDialog: {
    Name: 'ConfirmDoctorDialog',
    Description: 'ConfirmDoctorDialog description',
    Component: ConfirmDoctorDialog,
  },
  FinalConfirmReportDialog: {
    Name: 'FinalConfirmReportDialog',
    Description: 'FinalConfirmReportDialog description',
    Component: FinalConfirmReportDialogContainer,
  },
  NoticeDialog: {
    Name: 'NoticeDialog',
    Description: 'NoticeDialog description',
    Component: NoticeDialog,
  },
};

export default Dialog;
