import React from 'react';
import ReactDOM from 'react-dom';
// Internationalization
import 'intl';
import { IntlProvider } from 'react-intl';
import Korean from 'lang/compiled/ko.json';
import English from 'lang/compiled/en.json';
// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// App
import AppContainer from 'redux/container/AppContainer';
import configureStore from 'redux/configureStore';
import { getLocale } from 'util/Utility';
import Const from 'constant/Const';

const { store, persistor } = configureStore();
const locale = getLocale();

function loadLocaleData(locale) {
  switch (locale) {
    case 'ko':
      return Korean;
    default:
      return English;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider
      locale={locale}
      defaultLocale={Const.LANGUAGE.KO}
      messages={loadLocaleData(locale)}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppContainer />
        </PersistGate>
      </Provider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
