const LocalStorageKey = {
  TOKEN_TYPE: 'token_type',
  ACCESS_TOKEN: 'access_token',
  REFESH_TOKEN: 'refresh_token',
  DO_NOT_SHOW_NOTICE_POPUP_TIL_THIS_DATE: 'doNotShowNoticePopupTilThisDate',
  SIDE_PANEL_STATE: 'side_panel_state',
  REPORT_STATUS: 'report_status',
  REPORT_INFO: 'report_info',
};

export default LocalStorageKey;
