const API_URL = `${window.location.origin}/api`;
const REPORT_API_URL = `${window.location.origin}/report`;

const UrlList = {
  /**
   * Authentication
   **/
  getAuthUrl: function () {
    return `${API_URL}/auth`;
  },
  getEmailCheckUrl: function () {
    return `${this.getAuthUrl()}/email-check`;
  },
  getPasswordResetUrl: function () {
    return `${this.getAuthUrl()}/password-reset`;
  },
  getPasswordResetEmailWithTokenUrl: function () {
    return `${this.getAuthUrl()}/password-reset-email-with-token`;
  },
  getPasswordResetEmailUrl: function () {
    return `${this.getAuthUrl()}/password-reset-email`;
  },
  getPasswordResetEmailCompleteUrl: function () {
    return `${this.getAuthUrl()}/password-reset-email-complete`;
  },
  getPasswordResetNextTimeUrl: function () {
    return `${this.getAuthUrl()}/password-reset-next-time`;
  },
  getPasswordResetWithOldPasswordUrl: function () {
    return `${this.getAuthUrl()}/password-reset-with-old-password`;
  },
  getLoginUrl: function () {
    return `${this.getAuthUrl()}/token/login`;
  },
  getLogoutUrl: function () {
    return `${this.getAuthUrl()}/token/logout`;
  },
  getTokenRefreshUrl: function () {
    return `${this.getAuthUrl()}/token/refresh`;
  },
  getUserConsentUrl: function () {
    return `${this.getAuthUrl()}/user-consent`;
  },
  getReadMyInformationURL: function () {
    return `${API_URL}/me`;
  },

  /**
   * ECG Tests
   **/
  getEcgTestsUrl: function () {
    return `${API_URL}/ecg-tests`;
  },
  getEcgTestsIdUrl: function (ecgTestId) {
    return `${this.getEcgTestsUrl()}/${ecgTestId}`;
  },
  getEcgTestsIdRevertUrl: function (ecgTestId) {
    return `${this.getEcgTestsIdUrl(ecgTestId)}/revert-customer-confirm`;
  },
  getEcgTestsValidateUploadUrl: function () {
    return `${this.getEcgTestsUrl()}/validate-upload`;
  },
  getEcgTestsUploadEcgUrl: function () {
    return `${this.getEcgTestsUrl()}/upload-ecg`;
  },
  getEcgTestsIdReturnDeviceUrl: function (ecgTestId) {
    return `${this.getEcgTestsIdUrl(ecgTestId)}/return-device`;
  },
  getEcgTestsIdConfirmReviewUrl: function (ecgTestId) {
    return `${this.getEcgTestsIdUrl(ecgTestId)}/confirm`;
  },
  getEcgTestsIdEditDoneReviewUrl: function (ecgTestId) {
    // only for partner
    return `${this.getEcgTestsIdUrl(ecgTestId)}/cloud-status/edit-done`;
  },
  getEcgTestsIdCompleteUploadUrl: function (ecgTestId) {
    return `${this.getEcgTestsIdUrl(ecgTestId)}/complete-upload`;
  },

  /**
   * Patch ECGs
   **/
  getPatchEcgsUrl: function () {
    return `${API_URL}/patch-ecgs`;
  },
  getPatchEcgsIdUrl: function (ecgTestId) {
    return `${this.getPatchEcgsUrl()}/${ecgTestId}`;
  },
  getPatchEcgsIdDailyHeartRateUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/daily-heart-rate`;
  },
  getPatchEcgsIdExplorerUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/explorer`;
  },
  getPatchRawEcgsUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/raw`;
  },
  getPatchEcgsIdEventCountsUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/event-count`;
  },
  bulkConfirmUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/bulk-confirm`;
  },
  getPatchEcgsIdHrHistogramUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/histogram`;
  },
  getPatchEcgsIdStatistics: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/statistics`;
  },
  getPatchEcgsEctopic: function (ecgTestId) {
    return `${this.getPatchEcgsUrl(ecgTestId)}/ectopic`;
  },
  getPatchEcgsIdBeatsUrl: function (ecgTestId, suffix) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/beats/${suffix}`;
  },
  limitUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/limit`;
  },
  revertUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/revert`;
  },
  getEctopicUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/ectopic`;
  },
  getEctopicDetailUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/ectopic/filter/type`;
  },
  getEctopicFilterTypeUrl: function (ecgTetsId) {
    return `${this.getEctopicUrl(ecgTetsId)}/filter/type`;
  },
  getEctopicFilterWaveformIndexUrl: function (ecgTetsId) {
    return `${this.getEctopicUrl(ecgTetsId)}/filter/waveform-index`;
  },
  getEctopicFilterWaveformIndexRangeUrl: function (ecgTetsId) {
    return `${this.getEctopicUrl(ecgTetsId)}/filter/waveform-index-range`;
  },
  getBeatsUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/beats`;
  },
  getBeatsFilterBpmUrl: function (ecgTetsId) {
    return `${this.getBeatsUrl(ecgTetsId)}/filter/bpm`;
  },
  getBeatsFilterWaveformIndexRangeUrl: function (ecgTetsId) {
    return `${this.getBeatsUrl(ecgTetsId)}/filter/waveform-index-range`;
  },
  /** Will be DEPRECATE */
  getGetEventCountsUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/event-count`;
  },

  // Patient Events
  getPatientEventsUrl: function () {
    return `${API_URL}/patient-events`;
  },

  // Time Events
  getTimeEventsUrl: function () {
    return `${API_URL}/time-events`;
  },
  getTimeEventsIdUrl: function (timeEventId) {
    return `${this.getTimeEventsUrl()}/${timeEventId}`;
  },

  /**
   * Events
   **/
  getEventsUrl: function () {
    return `${API_URL}/events`;
  },
  getEventsIdUrl: function (eventId) {
    return `${this.getEventsUrl()}/${eventId}`;
  },
  getEventsIdConfirmUrl: function (eventId) {
    return `${this.getEventsIdUrl(eventId)}/confirm`;
  },

  /**
   * TimeEvents
   */
  getTimeEventDetail: function (params) {
    return `${this.getTimeEventsUrl(params)}`;
  },
  getTimeEventDetailById: function (params) {
    return `${this.getTimeEventsIdUrl(params)}`;
  },

  getTimeEventDetail: function (params) {
    return `${this.getTimeEventsUrl(params)}`;
  },

  /**
   * Reports
   */
  getReportsUrl: function () {
    return `${API_URL}/reports`;
  },
  getReportsDetailURL: function (reportId) {
    return `${this.getReportsUrl()}/${reportId}/entire`;
  },
  getReportIdCalculateUrl: function (reportId) {
    return `${this.getReportsUrl()}/${reportId}/compute`;
  },
  getReportIdIsValidUrl: function (reportId) {
    return `${this.getReportsUrl()}/${reportId}/is-valid`;
  },
  getReportSelectedEventUrl: function (reportId) {
    return `${this.getReportsUrl()}/${reportId}/selected-events`;
  },
  getReportGenerateUrl: function () {
    return `${REPORT_API_URL}/generate`;
  },
  getReportHandleUrl: function (reportId) {
    return `${REPORT_API_URL}/${reportId}/events`;
  },
  getReportsStatistics: function (reportId) {
    return `${this.getReportsUrl()}/${reportId}/statistics`;
  },

  /**
   * Report Events
   */
  getReportEventsUrl: function () {
    return `${API_URL}/report-events`;
  },
  getReportEventsIdUrl: function (reportEventId) {
    return `${API_URL}/report-events/${reportEventId}`;
  },

  /**
   * Medical Staffs
   **/
  getMedicalStaffsUrl: function () {
    return `${API_URL}/medical-staffs`;
  },
  getMedicalStaffsUserNameUrl: function (userName) {
    return `${this.getMedicalStaffsUrl()}/${userName}`;
  },

  /**
   * User
   **/
  getUserUrl: function () {
    return `${API_URL}/user`;
  },
  getUserForgotPasswordUrl: function () {
    return `${this.getUserUrl()}/forgotpassword`;
  },
  getUserChangePasswordUrl: function () {
    return `${this.getUserUrl()}/changePassword`;
  },

  /**
   * Device
   **/
  getDeviceUrl: function () {
    return `${API_URL}/device`;
  },
  getDeviceIdUrl: function (deviceId) {
    return `${this.getDeviceUrl()}/${deviceId}`;
  },

  /**
   * Markings
   **/
  getDeviceMarkingsUrl: function (deviceId) {
    return `${this.getDeviceIdUrl(deviceId)}/markings`;
  },
  getDeviceMarkingsIdUrl: function (deviceId, markingsId) {
    return `${this.getDeviceMarkingsUrl(deviceId)}/${markingsId}`;
  },

  /**
   * Common
   **/
  getStaffPatientsUrl: function () {
    return `${API_URL}/staff/fetch-patient`;
  },
  getDeviceSummaryUrl: function (deviceId) {
    return `${API_URL}/device/${deviceId}/summary`;
  },
  getDeviceEcgTotalUrl: function (deviceId) {
    return `${API_URL}/device/${deviceId}/ecg/total`;
  },
};

export default UrlList;
