import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import Const from 'constant/Const';
import DateUtil from 'util/DateUtil';

import useAuthority from 'component/hook/useAuthority';
import usePrevious from 'component/hook/usePrevious';
import useValidateReportAndStatistics from 'component/hook/useValidateReportAndStatistics';
import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import FinalConfirmReportDialog from 'component/dialog/FinalConfirmReportDialog';
import { showDialog as showDialogRequested } from 'redux/duck/dialogDuck';
import {
  patchEcgTestRequested,
  confirmEcgTestReviewRequested,
  editDoneEcgTestReviewRequested,
  getValidReportRequested,
} from 'redux/duck/ecgTestsDuck';

const IS_CUSTOMER_WEB =
  process.env.REACT_APP_CLIENT_NAME === Const.CLIENT_NAME.WEB_APP;

function FinalConfirmReportDialogContainer(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { open, params, callback, onClose } = props;
  const {
    ecgTestId,
    // patientName,
    patientNumber,
    report,
    initConfirmedBy,
    // PrintReportDialogCallback,
  } = params;
  const {
    // rid: reportId,
    updatedAt,
    createdAt,
    reportFile,
    deIdReportFile,
    // validReport,
  } = report;

  // Store States
  const {
    ecgTestsValidReportState,
    ecgTestsPatchState,
    ecgTestsConfirmState,
    ecgTestsEditDoneState,
  } = useShallowEqualSelector(({ ecgTestsReducer: state }) => ({
    ecgTestsValidReportState: state.validReport,
    ecgTestsPatchState: state.patch,
    ecgTestsConfirmState: state.confirm,
    ecgTestsEditDoneState: state.editDone,
  }));

  // Dispatches
  const showDialog = useCallback(
    (dialogKey, params, callback) =>
      dispatch(showDialogRequested(dialogKey, params, callback)),
    [open, dispatch]
  );
  const getValidReport = useCallback(() => {
    dispatch(getValidReportRequested(ecgTestId));
  }, [open, dispatch]);
  const patchEcgTestConfirmedBy = useCallback(
    (confirmedBy, callback) =>
      dispatch(patchEcgTestRequested(ecgTestId, { confirmedBy }, callback)),
    [open, dispatch]
  );
  const confirmEcgTestReview = useCallback(
    (ecgTestId, reGenerateReport, callback) =>
      dispatch(
        confirmEcgTestReviewRequested(ecgTestId, reGenerateReport, callback)
      ),
    [open, dispatch]
  );
  const editDoneEcgTestReview = useCallback(
    (ecgTestId) => dispatch(editDoneEcgTestReviewRequested(ecgTestId)),
    [open, dispatch]
  );

  // local state
  const [initPending, setInitPending] = useState(true);
  const [submitPending, setSubmitPending] = useState(false);

  // custom hook
  const { relationType } = useAuthority();
  const { validateStt } = useValidateReportAndStatistics(ecgTestId, () =>
    onClose()
  );

  // ..etc
  const [
    //
    reportFileName,
    reportFileUrl,
  ] = useMemo(() => {
    const isCustomerUser = relationType === Const.USER_RELATION_TYPE.CUSTOMER;
    const shortTid = ecgTestId.split('-')[0];
    return [
      `${isCustomerUser ? patientNumber : shortTid}_${DateUtil.format(
        updatedAt || createdAt,
        'yyyy-MM-dd_HH-mm'
      )}.pdf`,
      isCustomerUser ? reportFile : deIdReportFile,
    ];
  }, [patientNumber, createdAt, updatedAt]);

  /**
   * 0: 행위 명사만,
   * 1: 행위 명사 + 조사,
   * 2: 다음 단계
   */
  const purposeText = useMemo(
    () =>
      relationType === Const.USER_RELATION_TYPE.PARTNER
        ? [
            intl.formatMessage({
              id: '99-purposeText-partner-01',
              description: '',
              defaultMessage: '편집',
            }),
            intl.formatMessage({
              id: '99-purposeText-partner-02',
              description: '',
              defaultMessage: '편집을',
            }),
            intl.formatMessage({
              id: '99-purposeText-partner-03',
              description: '',
              defaultMessage: '휴이노로',
            }),
          ]
        : [
            intl.formatMessage({
              id: '99-purposeText-notPartner-01',
              description: '',
              defaultMessage: '검토',
            }),
            intl.formatMessage({
              id: '99-purposeText-notPartner-02',
              description: '',
              defaultMessage: '검토를',
            }),
            intl.formatMessage({
              id: '99-purposeText-notPartner-03',
              description: '',
              defaultMessage: '담당 병원으로',
            }),
          ],
    [relationType]
  );

  const doneRequest = () =>
    IS_CUSTOMER_WEB
      ? confirmEcgTestReview(ecgTestId, true)
      : editDoneEcgTestReview(ecgTestId, relationType);

  const reportOnClick = useCallback(() => {
    reportFileUrl && window.open(reportFileUrl);
  }, [reportFileUrl, reportFileName]);

  const onSubmit = useCallback(
    (newConfirmedBy) => {
      try {
        setSubmitPending(true);
        if (newConfirmedBy.value === initConfirmedBy?.value) {
          // 검토완료 또는 편집완료 처리, 요청자 계정 유형에 따라 결과 달라짐
          doneRequest();
        } else {
          // 변경된 확인의 저장 처리
          patchEcgTestConfirmedBy(newConfirmedBy.value);
        }
      } catch (error) {
        console.error('확인의 처리시 오류가 발생했습니다.', error);
      }
    },
    [ecgTestId, initConfirmedBy]
  );

  useEffect(() => {
    if (open) {
      getValidReport();
    }
  }, [open]);

  // 리포트 유효성 검증 요청의 응답 처리
  const prevEcgTestsValidReportState = usePrevious(ecgTestsValidReportState);
  useEffect(() => {
    if (
      prevEcgTestsValidReportState &&
      prevEcgTestsValidReportState.pending &&
      !ecgTestsValidReportState.pending
    ) {
      if (!ecgTestsValidReportState.error) {
        if (ecgTestsValidReportState.data) {
          // 검토 완료 Dialog 팝업
          setInitPending(false);
        } else {
          // 리포트 생성 Dialog 팝업
          showDialog('ConfirmDialog', {
            title: intl.formatMessage({
              id: '99-FinalConfirmReportDialog-ConfirmDialog-title-01',
              description:
                '리포트에 반영되지 않은 이벤트 수정 이력이 있습니다.',
              defaultMessage:
                '리포트에 반영되지 않은 이벤트 수정 이력이 있습니다.',
            }),
            message: intl.formatMessage(
              {
                id: '99-FinalConfirmReportDialog-ConfirmDialog-message-01',
                description:
                  '리포트를 다시 생성해주세요. 리포트 생성 후 검토 완료 상태로 변경할 수 있습니다.',
                defaultMessage:
                  '리포트를 다시 생성해주세요. 리포트 생성 후 {purposeText} 완료 상태로 변경할 수 있습니다.',
              },
              { purposeText: purposeText[0] }
            ),
            cancelButtonText: intl.formatMessage({
              id: '99-FinalConfirmReportDialog-ConfirmDialog-cancelButtonText-01',
              description: '닫기',
              defaultMessage: '닫기',
            }),
            confirmButtonText: intl.formatMessage({
              id: '99-FinalConfirmReportDialog-ConfirmDialog-confirmButtonText-01',
              description: '다시 생성하기',
              defaultMessage: '다시 생성하기',
            }),
            onCancel: () => {
              onClose();
            },
            onSubmit: () => {
              validateStt('showDialog');
            },
          });
        }
      } else {
        onClose();
      }
    }
  }, [ecgTestsValidReportState.pending]);

  // 검사 정보에 확인의 수정 요청에 대한 응답 처리
  const prevEcgTestsPatchState = usePrevious(ecgTestsPatchState);
  useEffect(() => {
    if (
      prevEcgTestsPatchState &&
      prevEcgTestsPatchState.pending &&
      !ecgTestsPatchState.pending
    ) {
      if (!ecgTestsPatchState.error) {
        // 검토완료 또는 편집완료 처리, 요청자 계정 유형에 따라 결과 달라짐
        doneRequest();
      } else {
        // showDialog('AlertNetworkDialog');
      }
    }
  }, [ecgTestsPatchState.pending]);

  // 검토 완료 요청의 응답 처리
  const prevEcgTestsConfirmState = usePrevious(ecgTestsConfirmState);
  useEffect(() => {
    async function ecgTestConfirmReviewCallback() {
      if (
        prevEcgTestsConfirmState &&
        prevEcgTestsConfirmState.pending &&
        !ecgTestsConfirmState.pending
      ) {
        if (!ecgTestsConfirmState.error) {
          setSubmitPending(false);

          onClose();
          if (typeof callback === 'function') {
            callback();
          }
        } else {
          // showDialog('AlertNetworkDialog');
        }
      }
    }

    ecgTestConfirmReviewCallback();
  }, [ecgTestsConfirmState.pending]);

  // 편집 완료 요청의 응답 처리
  const prevEcgTestsEditDoneState = usePrevious(ecgTestsEditDoneState);
  useEffect(() => {
    async function confirmEcgTestReviewRequestedCallback() {
      if (
        prevEcgTestsEditDoneState &&
        prevEcgTestsEditDoneState.pending &&
        !ecgTestsEditDoneState.pending
      ) {
        if (!ecgTestsEditDoneState.error) {
          setSubmitPending(false);

          onClose();
          if (typeof callback === 'function') {
            callback();
          }
        } else {
          // showDialog('AlertNetworkDialog');
        }
      }
    }

    confirmEcgTestReviewRequestedCallback();
  }, [ecgTestsEditDoneState.pending]);

  // const parseInitConfirmedBy = (initConfirmedBy) => {
  //   let result = null;
  //   if (!!initConfirmedBy) {
  //     result = {
  //       label: initConfirmedBy?.firstName,
  //       value: initConfirmedBy?.username,
  //     };
  //   }
  //   return result;
  // };

  return !initPending ? (
    <FinalConfirmReportDialog
      {...{
        // Dialog Props
        open,
        params: {
          purposeText,
          reportFileName,
          reportOnClick,
          initConfirmedBy,
          onSubmit,
        },
        onClose,
        // Store States
        // Local States
        submitPending,
      }}
    />
  ) : null;
}

export default FinalConfirmReportDialogContainer;
