import React from 'react';
import { useIntl } from 'react-intl';
import AlertDialog from './AlertDialog';

function AlertExpireDialog(props) {
  const intl = useIntl();

  const {
    // Redux Dispatches
    logout,
    // Component Properties
    open,
    callback,
    onClose,
  } = props;

  return (
    <AlertDialog
      open={open}
      params={{
        message: intl.formatMessage({
          id: '99-AlertExpireDialog-AlertDialog-message-01',
          description: '여러 브라우저에서 중복 로그인 안내',
          defaultMessage:
            '다른 브라우저에서 동일한 아이디로 접속하여 자동 로그아웃 되었습니다.',
        }),
        confirmButtonText: intl.formatMessage({
          id: '99-Dialog-Button-title-confirm',
          description: '팝업 Dialog의 확인 버튼',
          defaultMessage: '확인',
        }),
      }}
      callback={logout}
      onClose={onClose}
      zIndex={13}
    />
  );
}

export default AlertExpireDialog;
