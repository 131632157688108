import React from 'react';
import styled from 'styled-components';

export const RadioContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  align-items: stretch;
  justify-content: stretch;
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const RadioButtonWrapper = styled.div`
  box-sizing: border-box;
  padding: 8px;
  flex: 1;
  border-radius: 32px;
  background-color: ${(props) =>
    props.checked
      ? props.theme.color.LIGHT_PRIMARY_BLUE
      : props.theme.color.WHITE};
  // border-width: ${(props) => (props.checked ? 2 : 1)}px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.checked || props.borderChecked
      ? props.theme.color.PRIMARY_BLUE
      : props.theme.color.MEDIUM};
  :hover {
    border-color: ${(props) => props.theme.color.PRIMARY_BLUE};
  }
  ${(props) =>
    props.focused &&
    `
        border-color: ${props.theme.color.PRIMARY_BLUE};
    `}
  transition: border-color 0.2s ease-in-out;
  :not(:last-child) {
    ${(props) => {
      if (props.direction === 'row') {
        return `margin-right: 10px;`;
      } else {
        return `margin-bottom: 10px;`;
      }
    }}
  }
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

const Label = styled.label`
  font-size: 14px;
  color: #242626;
`;

export function OnlyRadio(props) {
  const { id, value, checked, onChange, label } = props;
  return (
    <>
      <input
        type="radio"
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
        readOnly={typeof onChange !== 'function'}
      />
      <Label htmlFor={id}>{label}</Label>
    </>
  );
}

export function RadioButton(props) {
  const { id, value, checked, direction, onChange, label, disabled } = props;
  return (
    <RadioButtonWrapper
      disabled={disabled}
      direction={direction || 'row'}
      onClick={disabled ? null : onChange}>
      <input
        type="radio"
        id={id}
        value={value}
        checked={checked}
        onChange={() => {}}
      />
      <Label htmlFor={id}>{label}</Label>
    </RadioButtonWrapper>
  );
}

export function RadioButtonWithShortcut(props) {
  const {
    id,
    value,
    checked,
    borderChecked,
    direction,
    onChange,
    label,
    shortcut,
    disabled,
  } = props;
  return (
    <RadioButtonWrapper
      direction={direction || 'row'}
      onClick={disabled ? null : onChange}
      disabled={disabled}
      checked={checked}
      borderChecked={borderChecked}>
      <input
        type="radio"
        id={id}
        value={value}
        checked={checked}
        onChange={() => {}}
      />
      <Label htmlFor={id}>
        {`${label} (`}
        <Label
          style={{ textDecoration: 'underline', textUnderlineOffset: '1px' }}>
          {shortcut}
        </Label>
        {')'}
      </Label>
    </RadioButtonWrapper>
  );
}
