import AppColors from 'theme/AppColors';

export const BEAT_TYPE = {
  NORMAL: 0,
  APC: 1,
  VPC: 2,
  NOISE: 3,
};

export const TIME_EVENT_TYPE = {
  AF: 'AF',
  PAUSE: 'PAUSE',
  OTHERS: 'OTHERS',
  LEAD_OFF: 'LEAD_OFF', // (편집 제한 등을 위해) lead off 구간을 어떻게 관리할지에 대해 추가 논의가 필요하지만 우선은 이곳에 넣어둠
};

export const ECTOPIC_TYPE = {
  ISOLATE: 'ISOLATE',
  COUPLET: 'COUPLET',
  RUN: 'RUN', // 3개 이상의 연속된 apc or vpc. couplet과 run 사이에 추가 클래스가 생길 수도 있어 10으로.
};

export const REPORT_TYPE = {
  SUMMARY: 'SUMMARY',
  DETAIL: 'DETAIL',
  ADDITIONAL: 'ADDITIONAL',
};

export const EVENT_SECTION = {
  AF: TIME_EVENT_TYPE.AF,
  PAUSE: TIME_EVENT_TYPE.PAUSE,
  OTHERS: TIME_EVENT_TYPE.OTHERS,
  ISO_VPC: 'IsoVPC',
  ISO_APC: 'IsoAPC',
  COUPLET_VPC: 'CoupletVPC',
  COUPLET_APC: 'CoupletAPC',
  PATIENT: 'PTE',
  SVT: 'SVT',
  VT: 'VT',
};

export const REPORT_SECTION = {
  SUMMARY: 'SUMMARY',
  AF: 'AF',
  PAUSE: 'PAUSE',
  VT: 'VT',
  VE: 'VE',
  SVT: 'SVT',
  SVE: 'SVE',
  PTE: 'PTE',
  ADDITIONAL: 'ADDITIONAL',
};

export const EVENT_CONST_TYPES = {
  // time-event
  AF: `EVENT-TYPE-${TIME_EVENT_TYPE.AF}`,
  PAUSE: `EVENT-TYPE-${TIME_EVENT_TYPE.PAUSE}`,
  OTHERS: `EVENT-TYPE-${TIME_EVENT_TYPE.OTHERS}`,
  //
  ISO_VPC: `EVENT-TYPE-${ECTOPIC_TYPE.ISOLATE}-${BEAT_TYPE.VPC}`,
  COUPLET_VPC: `EVENT-TYPE-${ECTOPIC_TYPE.COUPLET}-${BEAT_TYPE.VPC}`,
  VE: `EVENT-TYPE-X-${ECTOPIC_TYPE.RUN}-${BEAT_TYPE.VPC}`,
  VT: `EVENT-TYPE-${ECTOPIC_TYPE.RUN}-${BEAT_TYPE.VPC}`,
  ISO_APC: `EVENT-TYPE-${ECTOPIC_TYPE.ISOLATE}-${BEAT_TYPE.APC}`,
  COUPLET_APC: `EVENT-TYPE-${ECTOPIC_TYPE.COUPLET}-${BEAT_TYPE.APC}`,
  SVE: `EVENT-TYPE-X-${ECTOPIC_TYPE.RUN}-${BEAT_TYPE.APC}`, // RUN이 아닌 경우
  SVT: `EVENT-TYPE-${ECTOPIC_TYPE.RUN}-${BEAT_TYPE.APC}`,
  //
  PATIENT: 'EVENT-TYPE-PATIENT',
  //
  NOTABLE: `EVENT-TYPE-${REPORT_TYPE.SUMMARY}`,
  ADDITIONAL: `EVENT-TYPE-${REPORT_TYPE.ADDITIONAL}`,
  //
  NORMAL: `EVENT-TYPE-${BEAT_TYPE.NORMAL}`,
  NOISE: `EVENT-TYPE-${BEAT_TYPE.NOISE}`,
  LEAD_OFF: `EVENT-TYPE-${TIME_EVENT_TYPE.LEAD_OFF}`,
};

export const EVENT_GROUP_TYPE = {
  EVENTS: 'EVENTS',
  REPORT: 'REPORT',
  BEATS: 'BEATS',
};

export const SIDE_PANEL_EVENT_GROUP = {
  [EVENT_GROUP_TYPE.EVENTS]: [
    {
      type: EVENT_CONST_TYPES.AF,
      label: 'AF',
      unit: 'Ep(s)',
      sortBy: '(Sort by Duration)',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.AF,
      eventSection: EVENT_SECTION.AF,
    },
    {
      type: EVENT_CONST_TYPES.PAUSE,
      label: 'Pause',
      unit: 'Ep(s)',
      sortBy: '(Sort by Duration)',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.PAUSE,
      eventSection: EVENT_SECTION.PAUSE,
    },
    {
      type: EVENT_CONST_TYPES.VT,
      label: 'VT',
      unit: 'Run(s)',
      sortBy: '(Sort by Beats)',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.RUN,
      timeEventType: null,
      eventSection: EVENT_SECTION.VT,
    },
    {
      type: EVENT_CONST_TYPES.ISO_VPC,
      label: 'Iso VPC',
      unit: 'Beat(s)',
      sortBy: '(Sort by Time)',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      timeEventType: null,
      eventSection: EVENT_SECTION.ISO_VPC,
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_VPC,
      label: 'Couplet VPC',
      unit: 'Beat(s)',
      sortBy: '(Sort by Time)',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      timeEventType: null,
      eventSection: EVENT_SECTION.COUPLET_VPC,
    },
    {
      type: EVENT_CONST_TYPES.SVT,
      label: 'SVT',
      unit: 'Run(s)',
      sortBy: '(Sort by Beats)',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.RUN,
      timeEventType: null,
      eventSection: EVENT_SECTION.SVT,
    },
    {
      type: EVENT_CONST_TYPES.ISO_APC,
      label: 'Iso APC',
      unit: 'Beat(s)',
      sortBy: '(Sort by Time)',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      timeEventType: null,
      eventSection: EVENT_SECTION.ISO_APC,
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_APC,
      label: 'Couplet APC',
      unit: 'Beat(s)',
      sortBy: '(Sort by Time)',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      timeEventType: null,
      eventSection: EVENT_SECTION.COUPLET_APC,
    },
    {
      type: EVENT_CONST_TYPES.OTHERS,
      label: 'Others',
      unit: 'Event(s)',
      sortBy: '(Sort by Time)',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.OTHERS,
      eventSection: EVENT_SECTION.OTHERS,
    },
    {
      // testResultReducer.patientEvents 에서 조회
      type: EVENT_CONST_TYPES.PATIENT,
      label: 'Patient Events',
      unit: 'Event(s)',
      sortBy: '(Sort by Time)',
      beatType: null,
      ectopicType: null,
      timeEventType: null,
      eventSection: EVENT_SECTION.PATIENT,
    },
  ],
  [EVENT_GROUP_TYPE.REPORT]: [
    {
      type: EVENT_CONST_TYPES.NOTABLE,
      label: 'Notable Rhythm Strip',
      max: 3,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.SUMMARY,
      eventType: null,
      ectopicType: null,
    },
    {
      // testResultReducer.patientEvents 에서 조회
      type: EVENT_CONST_TYPES.PATIENT,
      label: 'Patient Events',
      max: -1,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.PTE,
      eventType: null,
      ectopicType: null,
    },
    {
      type: EVENT_CONST_TYPES.AF,
      label: 'AF',
      max: 20,
      sortBy: '(Sort by Duration)',
      reportSection: REPORT_SECTION.AF,
      eventType: TIME_EVENT_TYPE.AF,
      ectopicType: null,
    },
    {
      type: EVENT_CONST_TYPES.PAUSE,
      label: 'Pause',
      max: 20,
      sortBy: '(Sort by Duration)',
      reportSection: REPORT_SECTION.PAUSE,
      eventType: TIME_EVENT_TYPE.PAUSE,
      ectopicType: null,
    },
    {
      type: EVENT_CONST_TYPES.VT,
      label: 'VT',
      max: 20,
      sortBy: '(Sort by Beats)',
      reportSection: REPORT_SECTION.VT,
      eventType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.RUN,
    },
    {
      type: EVENT_CONST_TYPES.VE,
      label: 'VEs(VPCs)',
      max: 20,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.VE,
      eventType: BEAT_TYPE.VPC,
      ectopicType: null, // TODO: 준호 - 영훈님, report event API 에서 VE 페이지에 담긴 이벤트 조회시 ectopicType 을 무엇으로 하는지 확인이 필요해 보입니다.
    },
    {
      type: EVENT_CONST_TYPES.SVT,
      label: 'SVT',
      max: 20,
      sortBy: '(Sort by Beats)',
      reportSection: REPORT_SECTION.SVT,
      eventType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.RUN,
    },
    {
      type: EVENT_CONST_TYPES.SVE,
      label: 'SVEs(APCs)',
      max: 20,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.SVE,
      eventType: BEAT_TYPE.APC,
      ectopicType: null, // TODO: 준호 - 영훈님, report event API 에서 SVE 페이지에 담긴 이벤트 조회시 ectopicType 을 무엇으로 하는지 확인이 필요해 보입니다.
    },
    {
      type: EVENT_CONST_TYPES.ADDITIONAL,
      label: 'Additional Strip',
      max: 20,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.ADDITIONAL,
      eventType: null,
      ectopicType: null,
    },
  ],
  [EVENT_GROUP_TYPE.BEATS]: [
    {
      type: EVENT_CONST_TYPES.ISO_VPC,
      label: 'Iso VPC',
      unit: 'Beat(s)',
      sortBy: '(Sort by Time)',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      timeEventType: null,
      eventSection: EVENT_SECTION.ISO_VPC,
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_VPC,
      label: 'Couplet VPC',
      unit: 'Beat(s)',
      sortBy: '(Sort by Time)',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      timeEventType: null,
      eventSection: EVENT_SECTION.COUPLET_VPC,
    },
    {
      type: EVENT_CONST_TYPES.ISO_APC,
      label: 'Iso APC',
      unit: 'Beat(s)',
      sortBy: '(Sort by Time)',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      timeEventType: null,
      eventSection: EVENT_SECTION.ISO_APC,
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_APC,
      label: 'Couplet APC',
      unit: 'Beat(s)',
      sortBy: '(Sort by Time)',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      timeEventType: null,
      eventSection: EVENT_SECTION.COUPLET_APC,
    },
  ],
};

// render 시 필요한 색상 정보와 언더라인 적용 시 사용할 offset 값 포함
export const EVENT_INFO = [
  {
    type: EVENT_CONST_TYPES.ISO_APC,
    timeEventType: '',
    beatType: BEAT_TYPE.APC,
    ectopicType: ECTOPIC_TYPE.ISOLATE,
    renderAttrs: {
      color: AppColors.SVE_600,
      className: `huinno-${EVENT_CONST_TYPES.ISO_APC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.COUPLET_APC,
    timeEventType: '',
    beatType: BEAT_TYPE.APC,
    ectopicType: ECTOPIC_TYPE.COUPLET,
    renderAttrs: {
      color: AppColors.SVE_600,
      className: `huinno-${EVENT_CONST_TYPES.COUPLET_APC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.SVT,
    timeEventType: '',
    beatType: BEAT_TYPE.APC,
    ectopicType: ECTOPIC_TYPE.RUN,
    renderAttrs: {
      color: AppColors.SVE_600,
      className: `huinno-${EVENT_CONST_TYPES.SVT}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.ISO_VPC,
    timeEventType: '',
    beatType: BEAT_TYPE.VPC,
    ectopicType: ECTOPIC_TYPE.ISOLATE,
    renderAttrs: {
      color: AppColors.VE_400,
      className: `huinno-${EVENT_CONST_TYPES.ISO_VPC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.COUPLET_VPC,
    timeEventType: '',
    beatType: BEAT_TYPE.VPC,
    ectopicType: ECTOPIC_TYPE.COUPLET,
    renderAttrs: {
      color: AppColors.VE_400,
      className: `huinno-${EVENT_CONST_TYPES.COUPLET_VPC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.VT,
    timeEventType: '',
    beatType: BEAT_TYPE.VPC,
    ectopicType: ECTOPIC_TYPE.RUN,
    renderAttrs: {
      color: AppColors.VE_400,
      className: `huinno-${EVENT_CONST_TYPES.VT}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.AF,
    timeEventType: TIME_EVENT_TYPE.AF,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.AF_400,
      offset: 0,
      className: `huinno-${EVENT_CONST_TYPES.AF}`,
      zIndex: 3,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.OTHERS,
    timeEventType: TIME_EVENT_TYPE.OTHERS,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.OTHERS_400,
      offset: 2.5,
      className: `huinno-${EVENT_CONST_TYPES.OTHERS}`,
      zIndex: 2,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.PAUSE,
    timeEventType: TIME_EVENT_TYPE.PAUSE,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.PAUSE_400,
      className: `huinno-${EVENT_CONST_TYPES.PAUSE}`,
      zIndex: 5,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.PATIENT,
    timeEventType: 'patient',
    beatType: -1,
    ectopicType: '',
    renderAttrs: {},
  },
  {
    type: EVENT_CONST_TYPES.NORMAL,
    timeEventType: 'normal',
    beatType: -1,
    ectopicType: '',
    renderAttrs: {},
  },
  {
    type: EVENT_CONST_TYPES.NOISE,
    timeEventType: 'noise', // 의도된 garbage 값
    beatType: BEAT_TYPE.NOISE,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.MEDIUM_DARK,
      className: `huinno-${EVENT_CONST_TYPES.NOISE}`,
      zIndex: 0,
      priorityZIndex: 0,
    },
  },
  {
    type: EVENT_CONST_TYPES.LEAD_OFF,
    timeEventType: TIME_EVENT_TYPE.LEAD_OFF,
    beatType: -1, // 의도된 garbage 값
    ectopicType: '',
    renderAttrs: {
      selectedAreaColor: AppColors.LEAD_OFF,
      unselectedAreaColor: AppColors.LEAD_OFF,
      selectedFillOpacity: 1,
      unselectedFillOpacity: 1,
      color: AppColors.MEDIUM_DARK,
      className: `huinno-${EVENT_CONST_TYPES.LEAD_OFF}`,
      zIndex: 1,
      priorityZIndex: 10,
    },
  },
];

export const EVENT_INFO_ITEM_TYPES = {
  RANGE: 'Range',
  AVG_HR: 'Avg HR',
  DURATION: 'Duration',
  BEATS: 'Beats',
  ONSET: 'Onset',
  TERM: 'Term.',
  BEAT_HR: 'Beat HR',
  TIME: 'Time',
  NINETYS_HR: '90s HR',
  NINETYS_AVG: '90s Avg',
  TRIGGER: 'Trigger',
};

export const PLACE_HOLDER_TYPE = {
  EMPTY_SELECTED_EVENT: 'empty-selected-event',
  EMPTY_SELECTED_REPORT_PAGE: 'empty-selected-report-page',
  EMPTY_EVENT_IN_REPORT: 'empty-event-in-report',
};

export const CONTEXT_MENU = {
  BEAT_TYPE: {
    NORMAL: {
      index: 0,
      label: 'Normal',
      shortLabel: 'N',
      FullName: 'Normal',
      shortcut: 'Q',
      value: BEAT_TYPE.NORMAL, // value: api전송시 request param에서 사용될 값
    },
    APC: {
      //
      index: 1,
      label: 'S',
      shortLabel: 'S',
      FullName: 'APC',
      shortcut: 'W',
      value: BEAT_TYPE.APC,
    },
    VPC: {
      //
      index: 2,
      label: 'V',
      shortLabel: 'V',
      FullName: 'VPC',
      shortcut: 'E',
      value: BEAT_TYPE.VPC,
    },
    QUESTIONABLE: {
      index: 3,
      label: 'Questionable',
      shortLabel: 'Q',
      FullName: 'Questionable',
      shortcut: 'R',
      value: BEAT_TYPE.NOISE,
    },
  },
  EPISODE_TYPE: {
    AF: { index: 0, label: 'AF', value: TIME_EVENT_TYPE.AF, shortcut: 'A' },
    PAUSE: {
      index: 1,
      label: 'Pause',
      value: TIME_EVENT_TYPE.PAUSE,
      shortcut: 'S',
    },
    OTHERS: {
      index: 2,
      label: 'Others',
      value: TIME_EVENT_TYPE.OTHERS,
      shortcut: 'D',
    },
  },
};

export const plotLineColorMap = {
  [BEAT_TYPE.NORMAL]: AppColors.BLUE_50,
  [BEAT_TYPE.APC]: AppColors.SVE_50,
  [BEAT_TYPE.VPC]: AppColors.VE_50,
  [BEAT_TYPE.NOISE]: AppColors.COOL_GRAY_50,
};
