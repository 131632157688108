import { connect } from 'react-redux';
import App from 'component/base/App';
import { authLogoutRequested } from 'redux/duck/authDuck';
import { showDialog } from 'redux/duck/dialogDuck';

const mapStateToProps = (state, ownProps) => {
  return {
    someDialogOpenedState: state.dialogReducer.isSomethingOpen,
    authState: state.authReducer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // Dialog
    showDialog: (dialogKey, params, callback) => {
      dispatch(showDialog(dialogKey, params, callback));
    },
    // Auth
    logout: () => dispatch(authLogoutRequested()),
  };
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
