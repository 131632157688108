import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import { showDialog } from 'redux/duck/dialogDuck';
import { fetchMedicalStaffsRequested } from 'redux/duck/medicalStaffsDuck';
import Const from 'constant/Const';
import Dropdown from 'component/ui/dropdown/Dropdown';

/**
 *
 * @param {string} placeholder
 * @param {{key: string, label: string, value: string}} initDoctor
 * @returns
 */
function useDoctorsDropdown(placeholder, initDoctor = null) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const intl = useIntl();

  const authState = useSelector((state) => state.authReducer);
  const medicalStaffFetchState = useSelector(
    (state) => state.medicalStaffsReducer.fetch
  );

  const [selectedDoctor, setSelectedDoctor] = useState(initDoctor);

  const callbackFetchDoctors = useCallback(
    () =>
      dispatch(
        fetchMedicalStaffsRequested(
          authState.user.hospital?.hid,
          1,
          1000,
          '',
          Const.STAFF_TYPE.DOCTOR.value
        )
      ),
    [authState]
  );

  const callbackShowDialog = useCallback((dialogKey, params, callback) => {
    dispatch(showDialog(dialogKey, params, callback));
  }, []);

  useEffect(() => {
    callbackFetchDoctors();
  }, []);

  const renderDoctorsDropdown = React.forwardRef((props, ref) => (
    <Dropdown
      style={props.style}
      placeholder={placeholder}
      items={[
        ...medicalStaffFetchState.data.map((doctor) => ({
          key: doctor.username,
          label: doctor.firstName,
          value: doctor.username,
        })),
        {
          key: 'addNewDocter',
          label: intl.formatMessage({
            id: '99-useDoctorsDropdown-Dropdown-DropdownItem-addNewDoctor',
            description: '의사 선택 Dropdown 의 의사 추가 옵션',
            defaultMessage: '의사 추가하기',
          }),
          value: 'addNewDocter',
          textColor: theme.color.PRIMARY_BLUE,
        },
      ]}
      selectedItem={selectedDoctor}
      onChange={(item, index) => {
        if (item.value === 'addNewDocter') {
          callbackShowDialog('NewDoctorDialog', {}, (newDoctor) => {
            setSelectedDoctor({
              label: newDoctor.firstName,
              value: newDoctor.username,
            });
            callbackFetchDoctors();
          });
        } else {
          setSelectedDoctor(item);
        }
      }}
      anchorContainerStyle={{
        padding: '8px 12px',
        minWidth: 198 - 24 - 2,
        width: '100%',
        ...props.anchorContainerStyle,
      }}
      itemContainerStyle={{
        borderColor: theme.color.MEDIUM_LIGHT,
        bottom: props.dropToTop ? 35 : null,
        maxHeight: 148.8, // 24.8 * 6
        overflowY: 'scroll',
        justifyContent: 'initial',
        ...props.itemContainerStyle,
      }}
      dropToTop={props.dropToTop}
    />
  ));

  return [selectedDoctor, renderDoctorsDropdown];
}

export default useDoctorsDropdown;
