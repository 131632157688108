import { combineReducers } from 'redux';
// Reducers
import dialogReducer from './duck/dialogDuck';
import authReducer from './duck/authDuck';
import passwordReducer from './duck/passwordDuck';
import userConsentReducer from './duck/userConsentDuck';
import ecgTestsReducer from './duck/ecgTestsDuck';
import patchEcgsReducer from './duck/patchEcgsDuck';
import eventsReducer from './duck/eventsDuck';
import medicalStaffsReducer from './duck/medicalStaffsDuck';
import reportsReducer from './duck/reportsDuck';
import reportReducer from './duck/reportDuck';
import deviceSummaryReducer from './duck/deviceSummaryDuck';
import deviceEcgTotalReducer from './duck/deviceEcgDuck';
import patientEventsReducer from './duck/patientEventsDuck';
import testResultReducer from './duck/testResultDuck';
import beatReviewReducer from './duck/beatReviewDuck';
import hrReviewReducer from './duck/hrReviewDuck';

const rootReducer = combineReducers({
  dialogReducer,
  authReducer,
  passwordReducer,
  userConsentReducer,
  ecgTestsReducer,
  patchEcgsReducer,
  eventsReducer,
  medicalStaffsReducer,
  reportsReducer,
  reportReducer,
  deviceSummaryReducer,
  deviceEcgTotalReducer,
  patientEventsReducer,
  testResultReducer,
  beatReviewReducer,
  hrReviewReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
