import { call, put, takeLatest } from 'redux-saga/effects';
import ApiManager from 'network/ApiManager';

// Actions
// Get List
const GET_REQUESTED = 'memo-web/patient-events/GET_REQUESTED';
const GET_SUCCEED = 'memo-web/patient-events/GET_SUCCEED';
const GET_FAILED = 'memo-web/patient-events/GET_FAILED';

// Reducer
const initialState = {
  pending: false,
  data: null,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Fetch
    case GET_REQUESTED:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case GET_SUCCEED:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_FAILED:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

// Action Creators
// Get List
export function getRequested(tid) {
  return {
    type: GET_REQUESTED,
    tid,
  };
}
function getSucceed(data) {
  return {
    type: GET_SUCCEED,
    data: data,
  };
}
function getFailed(error) {
  return { type: GET_FAILED, error: error };
}

// Sagas
export function* saga() {
  yield takeLatest(GET_REQUESTED, function* (action) {
    try {
      const { data } = yield call(ApiManager.getPatientEvents, {
        tid: action.tid,
        ordering: 'event_timestamp',
      });

      yield put(getSucceed(data.results));
    } catch (error) {
      yield put(getFailed(error));
    }
  });
}
