import { call, put, takeLatest } from 'redux-saga/effects';
import ApiManager from 'network/ApiManager';
import StatusCode from 'network/StatusCode';

// Actions
// Fetch
const FETCH_DEVICE_SUMMARY_REQUESTED =
  'memo-web/patient/FETCH_DEVICE_SUMMARY_REQUESTED';
const FETCH_DEVICE_SUMMARY_SUCCEED =
  'memo-web/patient/FETCH_DEVICE_SUMMARY_SUCCEED';
const FETCH_DEVICE_SUMMARY_FAILED =
  'memo-web/patient/FETCH_DEVICE_SUMMARY_FAILED';

// Reducer
const initialState = {
  pending: false,
  data: null,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Fetch
    case FETCH_DEVICE_SUMMARY_REQUESTED:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case FETCH_DEVICE_SUMMARY_SUCCEED:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case FETCH_DEVICE_SUMMARY_FAILED:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

// Action Creators
// Fetch
export function fetchDeviceSummaryRequested(deviceId) {
  return { type: FETCH_DEVICE_SUMMARY_REQUESTED, deviceId: deviceId };
}
export function fetchDeviceSummarySucceed(data) {
  return {
    type: FETCH_DEVICE_SUMMARY_SUCCEED,
    data: data,
  };
}
export function fetchDeviceSummaryFailed(error) {
  return { type: FETCH_DEVICE_SUMMARY_FAILED, error: error };
}

// Sagas
function* readDeviceSummary(action) {
  try {
    const { status, data } = yield call(
      ApiManager.readDeviceSummary,
      action.deviceId
    );

    yield put(fetchDeviceSummarySucceed(data));
  } catch (error) {
    yield put(fetchDeviceSummaryFailed(error));
  }
}

export function* saga() {
  yield takeLatest(FETCH_DEVICE_SUMMARY_REQUESTED, readDeviceSummary);
}
