import AppColors from 'theme/AppColors';
import {
  BEAT_TYPE,
  ECTOPIC_TYPE,
  EVENT_CONST_TYPES,
  REPORT_SECTION,
} from 'constant/EventConst';
import { CHART_CONST } from 'constant/ChartConst';
import { getEventInfoByQuery } from './EventConstUtil';
import { TEN_SEC_SCRIPT_DETAIL } from 'constant/ChartEditConst';

/**
 * @typedef {Object} TransformedRawType Raw API 응답값을 비즈니스 로직에서 참조하기해 가공된 구간 정보구조
 * @property {Timestamp} onsetMs 구간 시작 시간
 * @property {WaveformIndex} onsetWaveformIndex 구간 시작 시간
 * @property {Timestamp} terminationMs 구간 종료 시간
 * @property {WaveformIndex} terminationWaveformIndex 구간 종료 시간
 * @property {number[]} ecgData waveform 데이터 배열
 * @property {Object} beats 구간 beat 정보
 * @property {Object[]} noises 구간 beat type 중 Noise 의 구간 내 위치 정보
 * @property {Object[]} ectopics 구간 beat type 중 Noise 의 구간 내 위치 정보
 * @property {number} avgHr 구간 Heart Rate 평균값
 * @property {Object[]?} ectopicSeries 구간 beat type 중 APC, VPC 시각화를 위한 series 정보
 * @property {Object[]?} noiseSeries 구간 beat type 중 NOISE 시각화를 위한 series 정보
 * @property {Object[]?} beatTypeZones 구간 beat type 시각화를 위한 구간 정보, Highcharts series의 zones 로 표현
 * @property {Object[]?} noiseEvents 구간 beat type 중 Noise 의 구간 내 위치 정보 */
/**
 * Raw API 응답 데이터구조
 * @typedef RawResponseType
 * @property {Timestamp} onsetMs 구간 시작 시간
 * @property {WaveformIndex} onsetWaveformIndex 구간 시작 시간
 * @property {Timestamp} terminationMs 구간 종료 시간
 * @property {WaveformIndex} terminationWaveformIndex 구간 종료 시간
 * @property {number[]} ecgData waveform 데이터 배열
 * @property {number} avgHr 구간 Heart Rate 평균값
 */
/**
 * withBeat 값이 True 인 Raw API 응답값을 LongTermChart, ShortTermChart 에서 필요한 형태로 변환합니다.
 *
 * @param {RawResponseType[]} ecgRawList raw API 로 응답된 구간 데이터
 * @param {BeatEventInfoType[]} recordingStartMs 검사의 측정 시작시간 Timestamp
 * @returns {TransformedRawType[]}
 */
export const mergeRawListNBeatEventInfoList = (
  ecgRawList,
  beatEventInfoList
) => {
  try {
    beatEventInfoList.forEach((beatEventInfo) => {
      const targetIndex = ecgRawList.findIndex(
        (ecgRaw) =>
          ecgRaw.onsetWaveformIndex === beatEventInfo.onsetWaveformIndex &&
          ecgRaw.terminationWaveformIndex ===
            beatEventInfo.terminationWaveformIndex
      );
      if (targetIndex > -1) {
        const originalSeries = ecgRawList[targetIndex].ecgData;
        const ectopicSeries = beatEventInfo.ectopics.map((ectopicInfo) => ({
          data: originalSeries
            .slice(
              ectopicInfo.onsetLocalWaveformIndex,
              ectopicInfo.terminationLocalWaveformIndex
            )
            .map((value, index) => [
              ectopicInfo.onsetLocalWaveformIndex + index,
              value,
            ]),
          color:
            ectopicInfo.beatType === BEAT_TYPE.APC
              ? AppColors.SVE_600
              : AppColors.VE_600,
          zIndex: 5,
        }));
        const noiseSeries = beatEventInfo.noises.map((noiseInfo) => ({
          data: originalSeries
            .slice(
              noiseInfo.onsetLocalWaveformIndex,
              noiseInfo.terminationLocalWaveformIndex
            )
            .map((value, index) => [
              noiseInfo.onsetLocalWaveformIndex + index,
              value,
            ]),
          color: AppColors.MEDIUM_DARK,
          zIndex: 5,
        }));
        ecgRawList[targetIndex] = {
          ...ecgRawList[targetIndex],
          ...beatEventInfo,
          ectopicSeries,
          noiseSeries,
        };
      } else {
        // TODO: 준호 - 예외 처리 필요 상황!!!
        console.log(
          'TestResultDuckUtil.transformRawList',
          ' TODO: 준호 - 예외 처리 필요 상황!!!'
        );
      }
    });
  } catch (error) {
    console.error(
      'TestResultDuckUtil > mergeRawListNBeatEventInfoList\n',
      error
    );
  }

  return ecgRawList;
};

/**
 * withBeat 값이 True 인 Raw API 응답값을 LongTermChart, ShortTermChart 에서 필요한 형태로 변환합니다.
 *
 * @param {Array<{onsetMs, terminationMs, avgHr, minHr, maxHr, ecgData}>} ecgRawList raw API 로 응답된 구간 데이터
 * @returns {TransformedRawType[]}
 */
export const transformRawList = (ecgRawList) => {
  const createAt = new Date().getTime();

  const result = ecgRawList.map((raw) => {
    const baselineWaveformIndex = raw.onsetWaveformIndex;

    return {
      createAt,
      baselineWaveformIndex,
      ...raw,
    };
  });

  return result;
};

/**
 * @typedef {Object} TransformedEventType Time Event 와 Ectopic 정보를 비즈니스 로직에서 참조하기위해 가공된 정보구조
 * @property {Timestamp} createAt 정보 가공 시점
 * @property {Timestamp | undefined} onsetMs 이벤트 시작 시간
 * @property {number | undefined} onsetWaveformIndex 이벤트 시작 waveform 위치
 * @property {Timestamp | undefined} terminationMs 이벤트 종료 시간
 * @property {number | undefined} terminationWaveformIndex 이벤트 종료 waveform 위치
 * @property {string} type 이벤트 구분값, EVENT_CONST_TYPES 참조
 * @property {number | null} timeEventId 이벤트가 Time Event 정보라면, API 조회를 위한 식별값
 * @property {number | null} onsetRPeakIndex 이벤트가 Ectopic 정보라면, API 조회를 위한 식별값
 * @property {number} position 동일 이벤트 그룹 내 순번(type 에 따라 고유 기준으로 정렬된 이벤트 정보의 순번), 1부터 시작
 */
/**
 * 부정맥 이벤트 API 조회 결과를 비즈니스 로직에서 활용할 형태로 변환합니다.
 *
 * @param {Array} timeEventGroups
 * @returns {TransformedEventType[]}
 */
export const transformTimeEvents = (timeEventGroups) => {
  const createAt = new Date().getTime();

  let result = [];

  // Time Event 정보 가공
  for (const iIndex in timeEventGroups) {
    result = [
      ...result,
      ...timeEventGroups[iIndex].map((value, jIndex) => ({
        createAt,
        onsetMs: value.onsetMs,
        terminationMs: value.terminationMs,
        type: getEventInfoByQuery({ timeEventType: value.eventType }).type,
        timeEventId: value.id,
        onsetRPeakIndex: null,
        position: parseInt(jIndex) + 1,
      })),
    ];
  }

  return result;
};

const getHrAvg = (hrList) => {
  let availableBeats = 0;
  const hrSum = hrList.reduce((acc, cur) => {
    if (cur) {
      availableBeats++;
      return acc + cur;
    } else {
      return acc;
    }
  }, 0);
  /**
   * 소수점 0자리 까지
   */
  return parseInt(Number.parseFloat(hrSum / availableBeats).toFixed(0));
};

//
// transformEctopicWithBeats 의 내부 함수들
//
/** Lead-Off 구간 비교용 */
const _isCovered = (eventInfo, onset, termination, type) =>
  !(
    termination <= eventInfo[`onset${type}`] ||
    eventInfo[`termination${type}`] <= onset
  );
/** 30초 구간의 시작 waveformIndex 를 계산 */
const _getOnsetWaveformIndex = (beatWaveformIndex) =>
  beatWaveformIndex - (beatWaveformIndex % CHART_CONST.XAXIS_MAX);
/** 두 waveformIndex 사이의 가운데 지점을 계산 */
const _getMidWaveformIndex = (preWaveformIndex, postWaveformIndex) =>
  preWaveformIndex + Math.floor((postWaveformIndex - preWaveformIndex) / 2);
const _filterLeadOffLitByBeatsRange = (
  leadOffList,
  recordingStartMs,
  firstWaveformIndex,
  lastWaveformIndex
) => {
  const totalOnsetWaveformIndex = _getOnsetWaveformIndex(firstWaveformIndex);
  const totalOnsetMs = recordingStartMs + totalOnsetWaveformIndex * 4;
  const totalTerminationWaveformIndex = _getOnsetWaveformIndex(
    lastWaveformIndex - 1 + CHART_CONST.XAXIS_MAX
  );
  const totalTerminationMs =
    recordingStartMs + totalTerminationWaveformIndex * 4;

  return leadOffList
    .filter((value) =>
      _isCovered(value, totalOnsetMs, totalTerminationMs, 'Ms')
    )
    .map((value) => ({
      ...value,
      onsetWaveformIndex: Math.floor((value.onsetMs - recordingStartMs) / 4),
      terminationWaveformIndex: Math.floor(
        (value.terminationMs - recordingStartMs) / 4
      ),
    }));
};
/** Beat Event Info 를 생성 */
const _makeOverEventInfo = (beatEventBuffer, onsetEctopicData) => {
  const {
    preLeadOffInfo,
    postLeadOffInfo,
    preBeatWaveformIndex,
    postBeatWaveformIndex,
  } = beatEventBuffer;
  const ectopicType = ((beatsLength) => {
    if (beatsLength === 1) return ECTOPIC_TYPE.ISOLATE;
    else if (beatsLength === 2) return ECTOPIC_TYPE.COUPLET;
    else return ECTOPIC_TYPE.RUN;
  })(beatEventBuffer.waveformIndex.length);
  const onsetRPeakIndex = ((
    onsetRPeakIndex,
    onsetEctopicData,
    someIncludedWaveformIndex
  ) => {
    if (onsetRPeakIndex) {
      return onsetRPeakIndex;
    } else {
      if (
        onsetEctopicData &&
        onsetEctopicData.waveformIndex.includes(someIncludedWaveformIndex)
      ) {
        return onsetEctopicData.waveformIndex[0];
      } else {
        // console.log(
        //   'transformEctopicWithBeats > _makeOverEventInfo > onsetRPeakIndex',
        //   {
        //     beatEventBuffer,
        //     onsetEctopicData,
        //   }
        // );
        // return null;
        return someIncludedWaveformIndex;
      }
    }
  })(
    beatEventBuffer.onsetRPeakIndex,
    onsetEctopicData,
    beatEventBuffer.waveformIndex[0]
  );

  // 조건 1. 직전 Lead-Off 구간있다면, 해당 구간의 termination 지점 사용, 시작 지점 알고 있음
  // 조건 2. 직전 Beat 의 위치를 안다면, 직전 Beat 와 onset 가운데 지점 사용, 시작 지점 알고 있음
  // 조건 3. 30초 구간 시작 지점으로 임의 설정, 시작 지점 모름
  const [onsetWaveformIndex, hasOnsetMarker] = ((
    onsetLeadOffTermination,
    prev,
    onset
  ) => {
    if (onsetLeadOffTermination) {
      return [onsetLeadOffTermination, true];
    } else if (prev) {
      return [_getMidWaveformIndex(prev, onset), true];
    } else {
      return [_getOnsetWaveformIndex(onset), false];
    }
  })(
    preLeadOffInfo?.terminationWaveformIndex,
    preBeatWaveformIndex,
    beatEventBuffer.waveformIndex.at(0)
  );
  // 조건 1. 직후 Lead-Off 구간있다면, 해당 구간의 onset 지점 사용, 시작 지점 알고 있음
  // 조건 2. 직후 Beat 의 위치를 안다면, termination 과 직후 Beat 의 가운데 지점 사용, 시작 지점 알고 있음
  // 조건 3. 30초 구간 종료 지점으로 임의 설정, 시작 지점 모름
  const [terminationWaveformIndex, hasTerminationMarker] = ((
    terminationLeadOffOnset,
    termination,
    post
  ) => {
    if (terminationLeadOffOnset) {
      return [terminationLeadOffOnset, true];
    } else if (post) {
      return [_getMidWaveformIndex(termination, post), true];
    } else {
      return [
        _getOnsetWaveformIndex(termination - 1) + CHART_CONST.XAXIS_MAX,
        false,
      ];
    }
  })(
    postLeadOffInfo?.onsetWaveformIndex,
    beatEventBuffer.waveformIndex.at(-1),
    postBeatWaveformIndex
  );
  return {
    onsetRPeakIndex: onsetRPeakIndex,
    ectopicType,
    beatType: beatEventBuffer.beatType,
    waveformIndex: beatEventBuffer.waveformIndex,
    onsetWaveformIndex,
    hasOnsetMarker,
    terminationWaveformIndex,
    hasTerminationMarker,
    type:
      getEventInfoByQuery({
        beatType: beatEventBuffer.beatType,
        ectopicType,
      })?.type ?? EVENT_CONST_TYPES.NOISE,
  };
};

/**
 * Beats API 응답 데이터구조
 * @typedef BeatsResponseType
 * @property {WaveformIndex[]} waveformIndex Beat 의 R-Peak 위치
 * @property {number[]} beatType Beat 의 종류, i.e. 0: Normal, 1: APC, 2: VPC, 3: Noise(Questionable)
 * @property {number[]} hr Beat 의 HR, Noise 가 아닌 직전 Beat 의 R-Peak 거리를 기준으로 HR 계산
 */
/**
 * Ectopic API 응답 데이터구조
 * @typedef EctopicResponseType
 * @property {WaveformIndex[]} waveformIndex Ectopic 의 Beat R-Peak 위치 배열
 * @property {string} ectopicType Ectopic 의 ectopic 종류, i.e. 'ISOLATED', 'COUPLET', 'RUN'
 * @property {number} beatType Ectopic 의 beat 종류, i.e. 1: APC, 2: VPC
 * @property {number} hrMin Ectopic 의 구간 Heart Rate 최소값
 * @property {number} hrMax Ectopic 의 구간 Heart Rate 최대값
 * @property {number} hrAvg Ectopic 의 구간 Heart Rate 평균값
 * @property {number} position 동일 종류의 Ectopic 목록 중 해당 Ectopic 의 순번, 1~
 * @property {number} beatCount Ectopic 의 구간 내 Beat 수
 * @property {boolean} isFastest Ectopic 의 Avg. HR 이 제일 빠름 여부
 * @property {Timestamp} durationMs Ectopic 의 구간 길이, Millisecond 기준
 * @property {Object[]} registeredReport Ectopic 의 리포트 정보 배열
 */
/**
 * Event Marker 의 render 에 필요한 데이터구조
 * @typedef EventMarkerRenderDataType
 * @property {WaveformIndex} onsetLocalWaveformIndex render 되는 구간에서 시작점 위치
 * @property {Boolean} hasOnsetMarker render 되는 구간에서 시작점 포함 여부
 * @property {WaveformIndex} terminationLocalWaveformIndex render 되는 구간에서 종료점 위치
 * @property {Boolean} hasTerminationMarker render 되는 구간에서 종료점 위치
 */
/**
 * 30초(또는 10초) 구간의 Beats 와 Ectopic 구간정보, 그리고 Noise 구간정보 배열
 * @typedef BeatEventInfoType
 * @property {Timestamp} createAt 정보 가공 시간
 * @property {WaveformIndex} onsetWaveformIndex 구간의 시작 waveform index
 * @property {WaveformIndex} terminationWaveformIndex 구간의 종료 waveform index
 * @property {BeatsResponseType} beats 구간의 Beat 정보 구조
 * @property {(TransformedEventType & EventMarkerRenderDataType)[]} noises 구간의 Noise 구간정보 배열
 * @property {(TransformedEventType & EctopicResponseType & EventMarkerRenderDataType)[]} ectopics 구간의 Ectopic 구간정보 배열
 * @property {number} hrAvg 구간의 Heart Rate 평균값
 */

/**
 * 동일한 구간으로 API 조회한 Beat 데이터와 Ectopic 데이터를 가공하여 30초 구간정보로 가공
 * @param {BeatsResponseType} beats
 * @param {TransformedEventType[]} leadOffList
 * @param {Timestamp} recordingStartMs
 * @param {EctopicResponseType?} onsetEctopicData
 * @returns {{WaveformIndex: BeatEventInfoType}} BeatEventInfo 의 onsetWaveformIndex 를 키로 하는 Object
 */
export const transformEctopicWithBeats = (
  beats,
  leadOffList,
  recordingStartMs,
  onsetEctopicData
) => {
  try {
    const createAt = new Date().getTime();

    /** 가공하는 Beats 구간에 대한 Lead-Off 구간 목록 */
    const filteredLeadOffList = _filterLeadOffLitByBeatsRange(
      leadOffList,
      recordingStartMs,
      beats.waveformIndex.at(0),
      beats.waveformIndex.at(-1)
    );

    const thirtySecBeatEventInfoMap = {};
    const noiseInfoList = [];
    const ectopicInfoList = [];

    let curOnsetWaveformIndex = -1;
    let beatsBuffer = {
      waveformIndex: [],
      beatType: [],
      hr: [],
    };
    /*
    {
      preBeatWaveformIndex,
      postBeatWaveformIndex,
      beatType,
      waveformIndex,
      onsetWaveformIndex,
      terminationWaveformIndex,
      type,
      onsetRPeakIndex,
    }
    */
    let beatEventBuffer = null;

    for (const beatIndex in beats.beatType) {
      // 30초 구간의 beats 가공
      if (
        _getOnsetWaveformIndex(beats.waveformIndex[beatIndex]) !==
        curOnsetWaveformIndex
      ) {
        // 이전 30초 구간 종료
        if (curOnsetWaveformIndex > -1) {
          thirtySecBeatEventInfoMap[curOnsetWaveformIndex] = {
            createAt,
            onsetWaveformIndex: curOnsetWaveformIndex,
            terminationWaveformIndex:
              curOnsetWaveformIndex + CHART_CONST.XAXIS_MAX,
            beats: { ...beatsBuffer },
            hrAvg: getHrAvg(beatsBuffer.hr),
          };
        }
        // 신규 30초 구간 시작
        curOnsetWaveformIndex = _getOnsetWaveformIndex(
          beats.waveformIndex[beatIndex]
        );
        beatsBuffer = {
          waveformIndex: [],
          beatType: [],
          hr: [],
        };
      }
      beatsBuffer.waveformIndex = [
        ...beatsBuffer.waveformIndex,
        beats.waveformIndex[beatIndex],
      ];
      beatsBuffer.beatType = [
        ...beatsBuffer.beatType,
        beats.beatType[beatIndex],
      ];
      beatsBuffer.hr = [...beatsBuffer.hr, beats.hr[beatIndex]];

      // Beat Event 또는 Noise 구간 계산
      const leadOffInfo = ((onset, termination) => {
        const temp = filteredLeadOffList.find((value) =>
          _isCovered(value, onset, termination, 'WaveformIndex')
        );
        return temp ? JSON.parse(JSON.stringify(temp)) : null;
      })(
        beats.waveformIndex[beatIndex - 1] ?? 0,
        beats.waveformIndex[beatIndex]
      );
      if (parseInt(beatIndex) > 0) {
        if (
          beats.beatType[beatIndex] !== beats.beatType[beatIndex - 1] ||
          leadOffInfo
        ) {
          // Beat Type 이 불연속 지점 파악된 지점
          if (!beatEventBuffer) {
            // 구간 시작
            // beatEventBuffer 정보가 없었다가 불연속 점이 생기는 경우는 Normal Beat 에서 abnormal Beat 가 출현한 상황 뿐임
            beatEventBuffer = {
              preLeadOffInfo: leadOffInfo,
              preBeatWaveformIndex: beats.waveformIndex[beatIndex - 1],
              beatType: beats.beatType[beatIndex],
              waveformIndex: [beats.waveformIndex[beatIndex]],
              onsetRPeakIndex: beats.waveformIndex[beatIndex],
            };
          } else {
            // 구간 종료
            beatEventBuffer['postBeatWaveformIndex'] =
              beats.waveformIndex[beatIndex];
            beatEventBuffer['postLeadOffInfo'] = leadOffInfo;
            const eventInfo = _makeOverEventInfo(
              beatEventBuffer,
              onsetEctopicData
            );
            if (eventInfo.beatType === BEAT_TYPE.NOISE) {
              noiseInfoList.push(eventInfo);
            } else if (
              [BEAT_TYPE.APC, BEAT_TYPE.VPC].includes(eventInfo.beatType)
            ) {
              ectopicInfoList.push(eventInfo);
            }

            if (beats.beatType[beatIndex] !== BEAT_TYPE.NORMAL) {
              // 구간 시작
              beatEventBuffer = {
                preBeatWaveformIndex: beats.waveformIndex[beatIndex - 1],
                beatType: beats.beatType[beatIndex],
                waveformIndex: [beats.waveformIndex[beatIndex]],
                onsetRPeakIndex: beats.waveformIndex[beatIndex],
              };
            } else {
              // 새로 시작하는 구간 아님
              beatEventBuffer = null;
            }
          }
        } else if (beatEventBuffer?.waveformIndex) {
          // 이벤트 구간 연속 중
          beatEventBuffer.waveformIndex.push(beats.waveformIndex[beatIndex]);
        }
      } else {
        if (beats.beatType[beatIndex] !== BEAT_TYPE.NORMAL) {
          // 구간 시작, 하지만 시작지점 모름
          beatEventBuffer = {
            preLeadOffInfo: leadOffInfo,
            preBeatWaveformIndex: null,
            beatType: beats.beatType[beatIndex],
            waveformIndex: [beats.waveformIndex[beatIndex]],
            onsetRPeakIndex: null,
          };
        }
      }
    }
    // 취합되지 않은 정보 정리
    thirtySecBeatEventInfoMap[curOnsetWaveformIndex] = {
      createAt,
      onsetWaveformIndex: curOnsetWaveformIndex,
      terminationWaveformIndex: curOnsetWaveformIndex + CHART_CONST.XAXIS_MAX,
      beats: { ...beatsBuffer },
      hrAvg: getHrAvg(beatsBuffer.hr),
    };

    if (beatEventBuffer) {
      // 구간 종료, 하지만 종료지점 모름
      const lastWaveformIndex = beatEventBuffer.waveformIndex.at(-1);
      beatEventBuffer['postLeadOffInfo'] = ((onset, termination) => {
        const temp = filteredLeadOffList.find((value) =>
          _isCovered(value, onset, termination, 'WaveformIndex')
        );
        return temp ? JSON.parse(JSON.stringify(temp)) : null;
      })(lastWaveformIndex, lastWaveformIndex + CHART_CONST.XAXIS_MAX);
      beatEventBuffer['postBeatWaveformIndex'] = null;
      const eventInfo = _makeOverEventInfo(beatEventBuffer, onsetEctopicData);
      eventInfo.type === EVENT_CONST_TYPES.NOISE
        ? noiseInfoList.push(eventInfo)
        : ectopicInfoList.push(eventInfo);
      beatEventBuffer = null;
    }

    // 각 구간에 Event Info. 할당
    for (const key in thirtySecBeatEventInfoMap) {
      const onsetWaveformIndex = parseInt(key);
      const terminationWaveformIndex =
        onsetWaveformIndex + CHART_CONST.XAXIS_MAX;
      thirtySecBeatEventInfoMap[onsetWaveformIndex].noises =
        noiseInfoList.filter((noiseInfo) => {
          return !(
            terminationWaveformIndex <= noiseInfo.onsetWaveformIndex ||
            onsetWaveformIndex >= noiseInfo.terminationWaveformIndex
          );
        });
      thirtySecBeatEventInfoMap[onsetWaveformIndex].ectopics =
        ectopicInfoList.filter((ectopicInfo) => {
          return !(
            terminationWaveformIndex <= ectopicInfo.onsetWaveformIndex ||
            onsetWaveformIndex >= ectopicInfo.terminationWaveformIndex
          );
        });
    }

    return thirtySecBeatEventInfoMap;
  } catch (error) {
    console.error('TestResultDuckUtil > transformEctopicWithBeats\n', error);
  }
};

/**
 * 두 Beat Event Info Map 을 합친다.
 *
 * @param {*} prevMap 기존 확보된 Beat Event Info Map
 * @param {*} newMap 새로 확보된 Beat Event Info Map
 * @returns 신규 Beat Event Info 가 합쳐진 전체 Map
 */
export const mergeBeatEventInfoMap = (prevMap, newMap) => {
  return Object.assign(prevMap, newMap);
};

export const getInitRepresentativeStripInfo = (reportEventDetailData) => {
  const {
    reportSection,
    representativeOnsetIndex,
    representativeTerminationIndex,
  } = reportEventDetailData ?? {};

  if (!representativeOnsetIndex || !representativeTerminationIndex) {
    return {
      selectedMs: null,
      representativeOnsetIndex: null,
      representativeTerminationIndex: null,
    };
  } else {
    const interHalfWaveformLength = Math.ceil(
      (representativeTerminationIndex - representativeOnsetIndex) / 2
    );
    return {
      selectedMs:
        reportSection === REPORT_SECTION.ADDITIONAL || !interHalfWaveformLength
          ? null
          : representativeOnsetIndex + interHalfWaveformLength,
      representativeOnsetIndex,
      representativeTerminationIndex,
    };
  }
};

export const getSearchBeatsNEctopicListRangeAfterUpdateEvent = (
  onsetWaveformIndex,
  terminationWaveformIndex
) => {
  let result = {
    searchOnsetRequest: undefined,
    searchTerminationRequest: undefined,
  };
  const xAxisMax = CHART_CONST.XAXIS_MAX;

  const representativeOnsetWaveformIndex =
    onsetWaveformIndex - (onsetWaveformIndex % xAxisMax);
  const representativeTerminationWaveformIndex =
    terminationWaveformIndex - (terminationWaveformIndex % xAxisMax);
  const searchOnsetRequest =
    representativeOnsetWaveformIndex === 0
      ? 0
      : representativeOnsetWaveformIndex - 7500;
  const searchTerminationRequest =
    representativeTerminationWaveformIndex + 7500;

  result = {
    searchOnsetRequest,
    searchTerminationRequest,
  };

  return result;
};

/* 
  # 10s strip detail 관련 로직
    - detail beat info
    - avg hr
 */
// # 10s strip detail - detail beat info
export const _getFilterBeatsNEctopicList = (
  beatsNEctopicList,
  startTenSecStripWaveformIdx,
  endTenSecStripWaveformIdx
) => {
  let filterBeatsNEctopicList = [];

  for (let waveformIndex in beatsNEctopicList) {
    const parseWaveformIndex = parseInt(waveformIndex);
    if (
      !(
        parseWaveformIndex + 7500 < startTenSecStripWaveformIdx ||
        parseWaveformIndex > endTenSecStripWaveformIdx
      )
    ) {
      filterBeatsNEctopicList.push(beatsNEctopicList[parseWaveformIndex]);
    }
  }

  return filterBeatsNEctopicList;
};

// # 10s strip detail - get list
export const _getTenSecStripInfo = (
  filterBeatsNEctopicList,
  startTenSecStripWaveformIdx,
  endTenSecStripWaveformIdx,
  target
) => {
  let filterTargetData;

  let startBeatWaveformIndex, endBeatWaveformIndex;
  if (filterBeatsNEctopicList.length === 1) {
    startBeatWaveformIndex =
      filterBeatsNEctopicList[0].beats.waveformIndex.findIndex(
        (v) => v > startTenSecStripWaveformIdx
      );

    endBeatWaveformIndex =
      filterBeatsNEctopicList[0].beats.waveformIndex.findLastIndex(
        (v) => v < endTenSecStripWaveformIdx
      );

    filterTargetData = filterBeatsNEctopicList[0].beats[target].slice(
      startBeatWaveformIndex,
      endBeatWaveformIndex + 1
    );
  } else if (filterBeatsNEctopicList.length === 2) {
    startBeatWaveformIndex =
      filterBeatsNEctopicList[0].beats.waveformIndex.findIndex(
        (v) => v > startTenSecStripWaveformIdx
      );

    endBeatWaveformIndex =
      filterBeatsNEctopicList[1].beats.waveformIndex.findLastIndex(
        (v) => v < endTenSecStripWaveformIdx
      );

    const filterTargetData1 = filterBeatsNEctopicList[0].beats[target].slice(
      startBeatWaveformIndex
    );
    const filterTargetData2 = filterBeatsNEctopicList[1].beats[target].slice(
      0,
      endBeatWaveformIndex + 1
    );

    filterTargetData = [...filterTargetData1, ...filterTargetData2];
  }

  if (target === TEN_SEC_SCRIPT_DETAIL.WAVEFORM_INDEX) {
    filterTargetData = filterTargetData.map(
      (v) => v - startTenSecStripWaveformIdx
    );
  }

  return filterTargetData;
};

// # 10s strip detail - calc avg hr
export const _getHrAvg = (beatsNEctopicList) => {
  let result, sumHr;

  sumHr = beatsNEctopicList.reduce((acc, cur) => acc + cur, 0);
  result =
    Math.floor((sumHr / beatsNEctopicList.filter(Boolean).length) * 10) / 10;

  return result;
};
