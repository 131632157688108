import React, { useEffect, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import loadable from '@loadable/component';
// Emotion
import {
  createTheme,
  ThemeProvider as EmotionThemeProvider,
} from '@mui/material/styles';
// Styled-components
import { ThemeProvider } from 'styled-components';
import StyledComponentsTheme from 'theme/StyledComponentsTheme';
// Dialog
import CentralizedDialogContainer from 'redux/container/dialog/CentralizedDialogContainer';
// Layouts
import FullScreenLayout from 'component/base/FullScreenLayout';
import Layout from 'component/base/Layout';
// Etc
import ResultCode from 'network/ResultCode';
import Const from 'constant/Const';
import { debounce } from 'util/Utility';
import TrickUtil from 'util/TrickUtil';
import DialogUtil from 'util/DialogUtil';

// Pages
const LoginPageContainer = loadable(() =>
  import('redux/container/LoginPageContainer')
);
const ChangePasswordPageContainer = loadable(() =>
  import('redux/container/ChangePasswordPageContainer')
);
const ReleaseDormantPageContainer = loadable(() =>
  import('redux/container/ReleaseDormantPageContainer')
);
const MainPageContainer = loadable(() =>
  import('redux/container/MainPageContainer')
);
const TestResultPageContainer = loadable(() =>
  import('redux/container/TestResultPageContainer')
);
const MyAccountPageContainer = loadable(() =>
  import('redux/container/MyAccountPageContainer')
);
const UserManagementPageContainer = loadable(() =>
  import('redux/container/UserManagementPageContainer')
);
const UsageAgreementPageContainer = loadable(() =>
  import('redux/container/UsageAgreementPageContainer')
);
const PrivacyPolicyPage = loadable(() =>
  import('component/page/PrivacyPolicyPage')
);
const NotFoundPage = loadable(() => import('component/page/NotFoundPage'));

function App(props) {
  const {
    // Redux state
    someDialogOpenedState,
    authState,
    // Redux dispatch
    showDialog,
    logout,
  } = props;

  const favicon = useMemo(
    () => document.querySelectorAll('link[rel=icon]')[0],
    []
  );
  useEffect(() => {
    if (process.env.REACT_APP_CUSTOM_ENV === 'prod') {
      favicon.href = '/favicon_production/favicon.ico';
    } else if (process.env.REACT_APP_CUSTOM_ENV === 'qa') {
      favicon.href = '/favicon_staging/favicon.ico';
    } else if (process.env.REACT_APP_CUSTOM_ENV === 'dev') {
      favicon.href = '/favicon_dev/favicon.ico';
    } else {
      favicon.href = '/favicon_aux/favicon.ico';
    }
  }, []);

  let timeoutId = null;
  let flag = false;

  const handleEvent = (e) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (
      authState.isLoggedIn &&
      !flag &&
      !window.location.pathname.includes('login')
    ) {
      const duration = Const.SESSION_DURATION_TIME * 60 * 60 * 1000;

      timeoutId = setTimeout(() => {
        flag = true;
        showDialog(
          'AlertDialog',
          {
            message: `${Const.SESSION_DURATION_TIME}시간 동안 사용하지 않아 자동 로그아웃 되었습니다.`,
          },
          () => {
            flag = false;
            logout();
          }
        );
      }, duration);
    }
  };

  useEffect(() => {
    TrickUtil.setExpireAlert(() => showDialog('AlertExpireDialog'));
    TrickUtil.setNetworkAlert((errorInfo) =>
      showDialog('AlertNetworkDialog', { errorInfo })
    );
  }, []);

  useEffect(() => {
    someDialogOpenedState ? DialogUtil.holdBody() : DialogUtil.unholdBody();
  }, [someDialogOpenedState]);

  useEffect(() => {
    const debouncedHandlerEvent = debounce(handleEvent, 500);
    const handleEventList = ['scroll', 'click', 'mousemove', 'keydown'];

    for (const e of handleEventList) {
      document.addEventListener(e, debouncedHandlerEvent);
    }

    return () => {
      for (const e of handleEventList) {
        document.removeEventListener(e, debouncedHandlerEvent);
      }
    };
  }, [authState.isLoggedIn]);

  const theme = createTheme(StyledComponentsTheme);

  return (
    <EmotionThemeProvider theme={theme}>
      <ThemeProvider theme={StyledComponentsTheme}>
        <Router>
          <Switch>
            {/* Signing pages (FullScreenLayout) */}
            {/* <Route path={['/login']}>
            {authState.isLoggedIn ? (
              <Redirect to="/" />
            ) : (
              <FullScreenLayout>
                <Route path="/login" component={LoginPageContainer} />
              </FullScreenLayout>
            )}
          </Route> */}

            {/* Password Reset Cases Without Login (Layout) */}
            <Route path={['/change-password/forgot', '/change-password/new']}>
              <Layout>
                <Route component={ChangePasswordPageContainer} />
              </Layout>
            </Route>
            <Route path={['/release-dormant']}>
              <Layout>
                <Route component={ReleaseDormantPageContainer} />
              </Layout>
            </Route>

            {/* Other pages (Layout) */}
            <Route
              path="*"
              render={(routeProps) => {
                if (!authState.isLoggedIn) {
                  return (
                    <FullScreenLayout>
                      <Switch>
                        <Route path="/login" component={LoginPageContainer} />
                        <Redirect to="/login" />
                      </Switch>
                    </FullScreenLayout>
                  );
                } else if (
                  authState.data.code ===
                  ResultCode.AUTH_STATUS.CHANGE_PASSWORD_REQUIRED
                ) {
                  return (
                    <Switch>
                      <Route path="/change-password/recommended">
                        <Layout>
                          <Route component={ChangePasswordPageContainer} />
                        </Layout>
                      </Route>
                      <Redirect to="/change-password/recommended" />
                    </Switch>
                  );
                } else if (
                  authState.data.code === ResultCode.AUTH_STATUS.DORMANT
                ) {
                  return (
                    <Switch>
                      <Route path="/release-dormant">
                        <Layout>
                          <Route component={ReleaseDormantPageContainer} />
                        </Layout>
                      </Route>
                      <Redirect to="/release-dormant" />
                    </Switch>
                  );
                }

                if (!authState.user?.isConfirmed) {
                  return (
                    <Layout>
                      <Switch>
                        <Route
                          path="/usage-agreement"
                          component={UsageAgreementPageContainer}
                        />
                        <Route path="/privacy" component={PrivacyPolicyPage} />
                        <Redirect to="/usage-agreement" />
                      </Switch>
                    </Layout>
                  );
                }

                if (
                  !authState.isAdmin &&
                  routeProps.location.pathname === '/user-management'
                ) {
                  return <Redirect to="/" />;
                }

                return (
                  <Layout>
                    <Switch>
                      <Route exact path="/" component={MainPageContainer} />
                      <Route
                        exact
                        path="/test/:ecgTestId"
                        component={TestResultPageContainer}
                      />
                      <Route
                        exact
                        path="/my-account"
                        component={MyAccountPageContainer}
                      />
                      <Route path="/usage-agreement">
                        <Redirect to="/" />
                      </Route>
                      <Route
                        path="/change-password"
                        component={ChangePasswordPageContainer}
                      />
                      <Route
                        path="/release-dormant"
                        component={ReleaseDormantPageContainer}
                      />
                      <Route path="/privacy" component={PrivacyPolicyPage} />
                      <Route
                        path="/user-management"
                        component={UserManagementPageContainer}
                      />

                      <Route exact path="/not-found" component={NotFoundPage} />
                      <Redirect to="/not-found" />
                    </Switch>
                  </Layout>
                );
              }}
            />
          </Switch>
        </Router>

        {/* Centralized Dialogs */}
        <CentralizedDialogContainer {...props} />
      </ThemeProvider>
    </EmotionThemeProvider>
  );
}

export default App;
