import Const from 'constant/Const';

export const debounce = (func, delay) => {
  let timeoutId = null;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(func, delay, ...args);
  };
};

export const throttle = (func, delay) => {
  let throttled = false;
  return (...args) => {
    if (!throttled) {
      throttled = true;
      setTimeout(() => {
        func(...args);
        throttled = false;
      }, delay);
    }
  };
};

export const getLocale = () => {
  // if (/^en\b/.test(navigator.language)) return Const.LANGUAGE.EN;
  if (/^ko\b/.test(navigator.language)) return Const.LANGUAGE.KO;
  return Const.LANGUAGE.EN;
};

export const getFetchOrderListParam = (fetchOrderList) => {
  let fetchOrderListParam = [];

  for (let prop in fetchOrderList) {
    if (fetchOrderList[prop] === Const.ORDER_TYPE.DESCENDING)
      fetchOrderListParam.push('-' + prop);
    else fetchOrderListParam.push(prop);
  }

  return fetchOrderListParam;
};

/**
 * @param {[ {onset: timestamp, termination: timestamp} ... ]} blockSectionList
 * @returns [ timestamp, ... ]
 */
export const getSelectedTimestamp = (blockSectionList) => {
  // 전체 ECG에서 onset, termination객체로 이뤄진 배열에서 중복된 부분을 제거해 선택된 timestamp요소인 배열 반환
  let allSelectTimestamp = [];
  for (const blockObj of blockSectionList) {
    let start = blockObj.onset;
    if (blockObj.termination != 0) {
      while (start <= blockObj.termination) {
        allSelectTimestamp.push(start);
        start += 10;
      }
    } else {
      allSelectTimestamp.push(start);
    }
  }

  return [...new Set(allSelectTimestamp)];
};

/**
 * @param {*} $target
 * @param {*} type
 * @returns
 */
export const getParentComponent = ($target, cid) => {
  let $targetEl = $target;

  while (!!$targetEl && $targetEl !== null && $targetEl?.dataset.cid !== cid) {
    if ($targetEl?.tagName === 'BODY') return null;
    $targetEl = $targetEl?.parentElement;
  }
  return $targetEl;
};

export const isInViewport = ($el, { positionX, positionY }) => {
  const distance = $el.getBoundingClientRect();
  return (
    (positionY || distance.top) >= 0 &&
    (positionX || distance.left) >= 0 &&
    (positionY + distance.height || distance.bottom) <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    (positionX + distance.width || distance.right) <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isInViewportInfo = (
  $el,
  { positionX, positionY } = { positionX: undefined, positionY: undefined }
) => {
  const rectInfo = $el.getBoundingClientRect();

  if (!positionX || !positionY) {
    positionX = rectInfo.x;
    positionY = rectInfo.y;
  }
  const $elWidth = rectInfo.width;
  const $elHeight = rectInfo.height;
  let result = {
    top: positionY <= 0,
    right:
      positionX + $elWidth >=
      (window.innerWidth || document.documentElement.clientWidth),
    bottom:
      positionY + $elHeight >=
      (window.innerHeight || document.documentElement.clientHeight),
    left: positionX <= 0,
  };

  return result;
};

export const isPositiveNumberValue = (value) =>
  typeof value === 'number' && !isNaN(value) && value >= 0;

export const isPositiveWaveformIndexPair = (onset, termination) =>
  isPositiveNumberValue(onset) && isPositiveNumberValue(termination);
