import {
  CHART_EDIT_CONST,
  HIGHCHART_UNIT,
  REPORT_REPRESENTATIVE_STRIP,
  SELECTION_MARKER_TYPE,
  STRIP_TYPE,
  TEN_SEC_STRIP,
} from 'constant/ChartEditConst';
import { events } from './PubSubUtil';
import { EVENT_CONST_TYPES } from 'constant/EventConst';
import { getEventInfoByType } from './EventConstUtil';
import { CHART_CONST } from 'constant/ChartConst';

const ChartUtil = {
  renderBorderGrid: (
    chart,
    xAxisTickLength,
    lineWidth,
    xIntervals,
    theme,
    marginTop = 0
  ) => {
    const { x, y, width, height } = chart.plotBox;
    const yIntervals = 6;

    const renderer = chart.renderer;
    const yInterval = (height - lineWidth) / yIntervals;
    const xInterval = (width - lineWidth) / xIntervals;

    // Border
    if (chart.huinnoBorder) {
      chart.huinnoBorder.destroy();
    }
    chart.huinnoBorder = renderer
      .g()
      .attr({ class: 'huinno-chart-grid-g', zIndex: 0 })
      .add();

    renderer
      .rect(x, y, width - lineWidth / 2, height - lineWidth / 2)
      .attr({
        class: 'huinno-chart-border-rect',
        stroke: theme.color.MEDIUM,
        'stroke-width': lineWidth,
        zIndex: 7,
      })
      .add(chart.huinnoBorder);
    renderer
      .path([
        'M',
        x,
        y,
        'V',
        height + xAxisTickLength,
        'M',
        x + width - lineWidth / 2,
        y,
        'V',
        height + xAxisTickLength,
        'Z',
      ])
      .attr({
        className: 'huinno-chart-borde-path',
        stroke: theme.color.MEDIUM,
        'stroke-width': lineWidth,
      })
      .add(chart.huinnoBorder);

    // Grid
    if (chart.huinnoGrid) {
      chart.huinnoGrid.destroy();
    }
    chart.huinnoGrid = renderer
      .g()
      .attr({
        class: 'huinno-chart-grid-g',
        zIndex: 0,
      })
      .add();

    const minorYPath = [];
    for (let i = 1; i < yIntervals; i++) {
      minorYPath.push(
        'M',
        x + lineWidth / 2,
        y + lineWidth / 2 + i * yInterval,
        'H',
        width
      );
    }
    renderer
      .path([...minorYPath, 'Z'])
      .attr({
        className: 'huinno-chart-grid-h-path',
        stroke: theme.color.MEDIUM_LIGHT,
        'stroke-width': lineWidth,
      })
      .add(chart.huinnoGrid);

    const minorXPath = [];
    const majorXPath = [];
    for (let i = 1; i < xIntervals; i++) {
      if (i % 5 !== 0) {
        minorXPath.push(
          'M',
          x + lineWidth / 2 + i * xInterval,
          y + lineWidth / 2,
          'V',
          height + marginTop
        );
      } else {
        majorXPath.push(
          'M',
          x + lineWidth / 2 + i * xInterval,
          y + lineWidth / 2,
          'V',
          height + xAxisTickLength
        );
      }
    }
    renderer
      .path([...minorXPath, 'Z'])
      .attr({
        className: 'huinno-chart-grid-v-minor-path',
        stroke: theme.color.MEDIUM_LIGHT,
        'stroke-width': lineWidth,
      })
      .add(chart.huinnoGrid);
    renderer
      .path([...majorXPath, 'Z'])
      .attr({
        className: 'huinno-chart-grid-v-major-path',
        stroke: theme.color.MEDIUM,
        'stroke-width': lineWidth,
      })
      .add(chart.huinnoGrid);
  },

  /**
   * zIndex 기본 5 부터 상태에 따라 1 씩 차이
   * @param {*} chart
   * @param {*} param1
   */
  renderEventButton: (
    chart,
    { xAxisPoint, isSelected, title, clickCallbackFunction, theme, isClickable }
  ) => {
    const testEvent_PatientTrigger_xAxisPoint_toPixels =
      chart.xAxis[0].toPixels(xAxisPoint);

    const defaultAttrs = {
      // svg 설정 option
      'stroke-width': 0.5,
      stroke: theme.color.PRIMARY_BLUE,
      r: 2,
      fill: theme.color.PATIENT_NORMAL_BUTTON,
      zIndex: 5,
      padding: -1, // XXX: 준호 - 음수 값으로 인해 렌더될때 ❗️Highcharts Error❗️ 가 발생되지만, 정상적으로 동작함
      paddingRight: 2.5,
      paddingLeft: 2.5,
      // ⭐️svg 안의 style 설정 option
      style: {
        fontFamily: 'Spoqa Han Sans Neo',
        color: theme.color.PRIMARY_BLUE,
        fontSize: '11px',
        lineHeight: '130%',
        fontWeight: 500,
        cursor: isClickable ? 'pointer' : 'default',
      },
    };
    const SelectAttrs = {
      ...defaultAttrs,
      fill: theme.color.PRIMARY_BLUE,
      zIndex: 6,
      style: {
        ...defaultAttrs.style,
        color: theme.color.WHITE,
      },
    };
    const disabledAttrs = {
      ...defaultAttrs,
    };
    const hoverAttrs = (() => {
      if (isClickable) {
        return {
          ...defaultAttrs,
          fill: theme.color.PATIENT_HOVER_BUTTON,
          zIndex: 7,
        };
      } else if (isSelected) {
        return SelectAttrs;
      } else {
        return defaultAttrs;
      }
    })();

    const patientTrigger = chart.renderer
      .button(
        title,
        0, // x
        0, // y
        isClickable ? clickCallbackFunction : () => {},
        defaultAttrs, // theme
        hoverAttrs, // hoverState
        SelectAttrs, // selectState
        disabledAttrs // disabledState
      )
      .attr({
        class: `patient-button patientTrigger-button-${xAxisPoint}`,
      })
      .add(chart.patientButtonGroup);

    // 버튼 요소의 state 에 따라 0: 노말, 1: 호버, 2: 선택됨, 3: 비활성화 로 제어 가능
    patientTrigger.setState(isSelected ? 2 : 0);

    const halfOfPatientTriggerWidth = patientTrigger.width / 2;

    // ⭐️ patientTrigger x축 위치는 아래 align의 x,y property에 의해서 결정됩니다.
    patientTrigger.align(
      {
        align: 'left',
        x:
          testEvent_PatientTrigger_xAxisPoint_toPixels -
          halfOfPatientTriggerWidth, // ⭐️ patient box 가로 중심이 patient point(ECG point)로 오게 하는 과정.
        y: 2, // ⭐️ 10 char와 patient trigger button의 간격
      },
      false,
      null
    );
    chart.xAxis[0].addPlotLine({
      class: `patientTrigger-verticalLine-${xAxisPoint}`,
      value: xAxisPoint, // xAxis point
      width: 1,
      color: theme.color.PRIMARY_BLUE,
      zIndex: 6,
    });

    return patientTrigger;
  },

  /**
   * zIndex 기본 5 부터 상태에 따라 1 씩 차이
   * @param {*} chart
   * @param {*} param1
   */
  renderBeatsEventButton: (
    chart,
    {
      xAxisPoint,
      isSelected,
      title,
      clickCallbackFunction,
      theme,
      color,
      normalColor,
      hoverColor,
      y,
    }
  ) => {
    let testEvent_BeatButton_xAxisPoint_toPixels,
      defaultAttrs,
      hoverAttrs,
      selectAttrs,
      disabledAttrs,
      beatButton,
      halfOfBeatButtonWidth;

    testEvent_BeatButton_xAxisPoint_toPixels =
      chart.xAxis[0].toPixels(xAxisPoint);

    //https://api.highcharts.com/class-reference/Highcharts.SVGRenderer#button
    defaultAttrs = {
      r: 2,
      width: 8,
      height: 8,
      zIndex: 5,
      fill: normalColor,
      'stroke-width': 1,
      stroke: theme.color.MEDIUM,
      style: {
        fontFamily: 'Spoqa Han Sans Neo',
        color: theme.color[color],
        fontSize: '13px',
        fontWeight: 500,
      },
    };
    hoverAttrs = {
      ...defaultAttrs,
      fill: hoverColor,
      zIndex: 7,
    };
    selectAttrs = {
      ...defaultAttrs,
      stroke: (function () {
        if (title === 'N') return theme.color.PRIMARY_BLUE;
        else if (title === 'Q') return theme.color.BLACK;
        else if (title === 'S') return theme.color.SVE_600;
        else return theme.color[color];
      })(),
      fill: (function () {
        if (title === 'N') return theme.color.LIGHT_PRIMARY_BLUE;
        else if (title === 'Q') return theme.color.MEDIUM;
        else return theme.color[`LIGHT_${color}`];
      })(),
      zIndex: 6,
      style: {
        ...defaultAttrs.style,
        color: (function () {
          if (title === 'N') return theme.color.PRIMARY_BLUE;
          else if (title === 'Q') return theme.color.BLACK;
          else if (title === 'S') return theme.color.SVE_600;
          else return theme.color.PRIMARY_WHITE;
        })(),
      },
    };
    disabledAttrs = {
      ...defaultAttrs,
    };

    // https://api.highcharts.com/class-reference/Highcharts.SVGRenderer#button
    beatButton = chart.renderer
      .button(
        title,
        0, // x
        0, // y
        clickCallbackFunction,
        defaultAttrs, // theme
        hoverAttrs, // hoverState
        selectAttrs, // selectState
        disabledAttrs // disabledState
      )
      .attr({
        class: `beats-event-button-${xAxisPoint}`,
      })
      .add();

    // 글자 특성에 따른 x축 이동해 button 가운데로 정렬
    if (title === 'S' || title === 'V') {
      const beatButtonX = beatButton.element.children[1].getAttribute('x');
      beatButton.element.children[1].setAttribute(
        'x',
        parseInt(beatButtonX) + 0.5
      );
    }
    if (title === 'N' || title === 'Q') {
      const beatButtonX = beatButton.element.children[1].getAttribute('x');
      beatButton.element.children[1].setAttribute(
        'x',
        parseInt(beatButtonX) - 0.5
      );
    }

    // 버튼 요소의 state 에 따라 0: 노말, 1: 호버, 2: 선택됨, 3: 비활성화 로 제어 가능
    beatButton.setState(isSelected ? 2 : 0);

    halfOfBeatButtonWidth = beatButton.width / 2;

    // ⭐️ patientTrigger x축 위치는 아래 align의 x,y property에 의해서 결정됩니다.
    beatButton.align(
      {
        align: 'left',
        x: testEvent_BeatButton_xAxisPoint_toPixels - halfOfBeatButtonWidth,
        y: y, // ⭐️ 10 char와 beats button의 간격
      },
      false,
      null
    );

    return beatButton;
  },

  /**
   * @typedef EventInfoType
   * @prop {number} onsetLocalWaveformIndex 시작점, 차트로 시각화된 데이터 인덱스 기준
   * @prop {Boolean} hasOnsetMarker 시작 마커 사용 여부
   * @prop {number} terminationLocalWaveformIndex 종료점, 차트로 시각화된 데이터 인덱스 기준
   * @prop {Boolean} hasTerminationMarker 종료 마커 사용 여부
   * @prop {AppColor} selectedAreaColor 선택된 이벤트 마커의 에어리어 색상
   * @prop {AppColor} unselectedAreaColor 선택되지 않은 이벤트 마커의 에어리어 색상
   * @prop {AppColor | undefined} fillOpacity ONLY Noise 를 위한 속성
   * @prop {AppColor} color 이벤트 마커 색상
   * @prop {number | undefined} offset 이벤트 마커의 Under Line 간격, 생략시 render 안함
   * @prop {String} className 이벤트 마커 종류
   * @prop {number} zIndex 이벤트 마커 UI render 순위
   * @prop {number | undefined} priorityZIndex Click 이벤트를 수용하는 Event Marker 와 동일 크기의 Element 의 UI render 순위
   *
   * @param {*} chart
   * @param {EventInfoType} EventInfo
   * @returns {SVGElement & {setSelectedState: function, priorityElement: SVGElement}} 이벤트 마커 요소를 그룹핑한 <g> 요소, 선택 상태 변경을 위한 기능 있음
   */
  renderEvent: (
    chart,
    {
      onsetLocalWaveformIndex,
      hasOnsetMarker,
      terminationLocalWaveformIndex,
      hasTerminationMarker,
      selectedAreaColor,
      unselectedAreaColor,
      selectedFillOpacity,
      unselectedFillOpacity,
      color,
      offset,
      className,
      zIndex,
      priorityZIndex,
    }
  ) => {
    // ❗️❗️❗️ 이벤트 마커의 모든 요소의 위치는 transform 속성의 translate 기능을 통해 이루어집니다. ❗️❗️❗️
    const {
      x: plotX,
      y: plotY,
      width: plotWidth,
      height: plotHeight,
    } = chart.plotBox;
    const strokeWidth = 1;
    const triangleSvg = 'M4.5 0H0V4.5L4.5 0Z';
    const widthPerPoint = plotWidth / chart.series[0]?.data.length;

    const onsetXPixel = plotX + widthPerPoint * onsetLocalWaveformIndex;
    const terminationXPixel =
      plotX + widthPerPoint * terminationLocalWaveformIndex;
    const eventLengthPixel = terminationXPixel - onsetXPixel;

    const marker = chart.renderer
      .g()
      .attr({
        class: `huinno-event-marker ${className}`,
        zIndex,
      })
      .add(chart.eventMarkerGroup);

    // 선택된 이벤트 구간 강조를 위한 영역 생성
    const areaBaseAttrs = {
      class: `huinno-event-marker-area  ${className}`,
      transform: `translate(${onsetXPixel}, ${plotY})`,
      zIndex: 0,
    };
    const eventMarkerArea = chart.renderer
      .rect(0, 0, eventLengthPixel, plotHeight, 0, 0)
      .attr({
        ...areaBaseAttrs,
      })
      .css({ fillOpacity: unselectedFillOpacity, fill: unselectedAreaColor })
      .add(marker);

    /**
     * 선택된 이벤트 구간 강조 여부 처리 용
     * @param {*} isSelected true 면 강조된 색으로 변경
     */
    marker.setSelectedState = (isSelected) => {
      if (isSelected) {
        marker.priorityElement.attr({
          class: `huinno-event-marker-priority huinno-event-marker-priority-selected ${className}`,
          transform: `translate(${onsetXPixel}, ${plotY})`,
          zIndex: 9,
        });

        eventMarkerArea.css({
          fillOpacity: selectedFillOpacity,
          fill: selectedAreaColor,
        });
      } else {
        marker.priorityElement.attr({
          class: `huinno-event-marker-priority  ${className}`,
          transform: `translate(${onsetXPixel}, ${plotY})`,
          zIndex: priorityZIndex,
        });

        eventMarkerArea.css({
          fillOpacity: unselectedFillOpacity,
          fill: unselectedAreaColor,
        });
      }
    };

    // 이벤트 구간 시작 지점이 있는 경우 생성
    if (hasOnsetMarker) {
      chart.renderer
        .path()
        .attr({
          class: 'huinno-event-marker-onset-top',
          d: triangleSvg,
          transform: `translate(${onsetXPixel}, ${plotY})`,
          fill: color,
          zIndex: 2,
        })
        .add(marker);
      chart.renderer
        .path()
        .attr({
          class: 'huinno-event-marker-onset-bottom',
          d: triangleSvg,
          // ⭐️ rotate: deg 단위를 쓰지 않는다.
          // ⭐️ translate: px 단위를 쓰지 않는다.
          transform: `translate(${onsetXPixel}, ${
            plotY + plotHeight
          }) rotate(270)`,
          fill: color,
          zIndex: 2,
        })
        .add(marker);
      chart.renderer
        .rect(0, 0, strokeWidth, plotHeight, 0, 0)
        .attr({
          class: 'huinno-event-marker-onset-line',
          transform: `translate(${onsetXPixel}, ${plotY})`,
          fill: color,
          opacity: 0.5,
          zIndex: 1,
        })
        .add(marker);
    }
    // 이벤트 구간 종료 지점이 있는 경우 생성
    if (hasTerminationMarker) {
      chart.renderer
        .path()
        .attr({
          class: 'huinno-event-marker-term-top',
          d: triangleSvg,
          transform: `translate(${terminationXPixel}, ${plotY}) rotate(90)`,
          fill: color,
          zIndex: 2,
        })
        .add(marker);
      chart.renderer
        .path()
        .attr({
          class: 'huinno-event-marker-term-bottom',
          d: triangleSvg,
          transform: `translate(${terminationXPixel}, ${
            plotY + plotHeight
          }) rotate(180)`,
          fill: color,
          zIndex: 2,
        })
        .add(marker);
      chart.renderer
        .rect(0, 0, strokeWidth, plotHeight, 0, 0)
        .attr({
          class: 'huinno-event-marker-term-line',
          transform: `translate(${terminationXPixel - strokeWidth}, ${plotY})`,
          fill: color,
          opacity: 0.5,
          zIndex: 1,
        })
        .add(marker);
    }
    // AF, Others 구간에 언더라인 생성
    if (!isNaN(offset)) {
      chart.renderer
        .rect(0, 0, eventLengthPixel, strokeWidth, 0, 0)
        .attr({
          class: 'huinno-event-marker-under-line',
          transform: `translate(${onsetXPixel}, ${
            plotY + plotHeight + offset
          })`,
          fill: color,
          zIndex: 0,
        })
        .add(marker);
    }

    // 별도의 Click Event Handler 를 적용하기 위한 요소
    if (!isNaN(priorityZIndex)) {
      marker.priorityElement = chart.renderer
        .rect(0, 0, eventLengthPixel, plotHeight, 0, 0)
        .attr({
          class: `huinno-event-marker-priority  ${className}`,
          transform: `translate(${onsetXPixel}, ${plotY})`,
          zIndex: priorityZIndex,
        })
        .css({ fill: unselectedAreaColor, fillOpacity: 0 })
        .add(chart.eventMarkerGroup);
    }

    return marker;
  },

  checkRenderedChart: (chart) => isNaN(chart.xAxis[0].toPixels(0)),

  chartEditStore: {
    [SELECTION_MARKER_TYPE.ONSET]: {
      representativeTimestamp: undefined,
      representativeWaveformIndex: undefined,
      clickedWaveformIndex: undefined,
      clickedTimestamp: undefined,
    },
    [SELECTION_MARKER_TYPE.TERMINATION]: {
      representativeTimestamp: undefined,
      representativeWaveformIndex: undefined,
      clickedWaveformIndex: undefined,
      clickedTimestamp: undefined,
    },
  },

  chartEdit: function () {
    /**
     * @param chartInst
     */
    let chartInst;
    /**
     * @param param2
     * @prop {function} setSelectionStrip
     * @prop {function} setTenSecStrip
     * @prop {number} timestamp
     * @prop {number} representativeWaveformIndex
     * @prop {number} recordingStartMs
     * @prop {object} theme
     */
    let param2,
      setSelectionStrip,
      setTenSecStrip,
      timestamp,
      representativeWaveformIndex,
      recordingStartMs,
      theme;

    if (arguments.length === 1) {
      param2 = arguments[0];
    } else if (arguments.length === 2) {
      chartInst = arguments[0];
      param2 = arguments[1];
    }

    if (!chartInst) {
      chartInst = document.querySelector(
        '.chartContainer .highcharts-plot-background'
      )?.highchartInst;
    }

    if (param2) {
      setSelectionStrip = param2.setSelectionStrip;
      setTenSecStrip = param2.setTenSecStrip;
      timestamp = param2.timestamp;
      representativeWaveformIndex = param2.representativeWaveformIndex;
      recordingStartMs = param2.recordingStartMs;
      theme = param2.theme;
    }

    let _selectionStrip = this.chartEditStore;

    return {
      getSelectionStrip() {
        return _selectionStrip;
      },

      hasOnsetSelectionMarker() {
        return (
          _selectionStrip[SELECTION_MARKER_TYPE.ONSET]
            .representativeTimestamp !== undefined
        );
      },

      hasTerminationSelectionMarker() {
        return (
          _selectionStrip[SELECTION_MARKER_TYPE.TERMINATION]
            .representativeTimestamp !== undefined
        );
      },
      /**
       * selection strip 생성 로직
       *
       * @param {event} 하이차트 클릭 이벤트 객체
       * @param {[Timestamp, Timestamp]} onset, termination selection marker timestamp
       * @returns
       */
      renderSelectionStrip(
        event,
        [onsetRepresentativeTimestamp, terminationRepresentativeTimestamp]
      ) {
        const constOnset = SELECTION_MARKER_TYPE.ONSET;
        const constTermination = SELECTION_MARKER_TYPE.TERMINATION;
        onsetRepresentativeTimestamp =
          _selectionStrip &&
          _selectionStrip[constOnset].representativeTimestamp;
        terminationRepresentativeTimestamp =
          _selectionStrip &&
          _selectionStrip[constTermination].representativeTimestamp;

        if (
          !this._validationSelectionMarker(event, [
            onsetRepresentativeTimestamp,
            terminationRepresentativeTimestamp,
          ])
        ) {
          return;
        }

        const { type, shiftKey, xAxis, clickedWaveformIndex } = event;
        let clickedXAxisPixel = event.clickedWaveformIndexPixels;
        let selectionMarkerType;

        if (_isClick()) {
          selectionMarkerType = SELECTION_MARKER_TYPE.ONSET;
          this.removeSelectionMarkerAll();
        } else if (_isShiftClick()) {
          selectionMarkerType = SELECTION_MARKER_TYPE.TERMINATION;

          if (
            _selectionStrip.TERMINATION.clickedWaveformIndex !== undefined &&
            representativeWaveformIndex + clickedWaveformIndex <
              _selectionStrip.ONSET.representativeWaveformIndex +
                _selectionStrip.ONSET.clickedWaveformIndex
          ) {
            this.removeOnsetSelectionMarker();
          } else {
            this.removeTerminationSelectionMarker();
          }
        }

        if (selectionMarkerType === SELECTION_MARKER_TYPE.ONSET) {
          _selectionStrip[SELECTION_MARKER_TYPE.ONSET] = {
            selectionMarkerType,
            representativeTimestamp: timestamp,
            representativeWaveformIndex,
            clickedWaveformIndex: Math.round(clickedWaveformIndex),
            extraParam: {
              isNoise: event.target.classList.contains(
                getEventInfoByType(EVENT_CONST_TYPES.NOISE).renderAttrs
                  .className
              ),
            },
          };
          _selectionStrip[SELECTION_MARKER_TYPE.TERMINATION] = {
            selectionMarkerType: SELECTION_MARKER_TYPE.TERMINATION,
            representativeTimestamp: undefined,
            representativeWaveformIndex: undefined,
            clickedWaveformIndex: undefined,
            extraParam: {
              isNoise: event.target.classList.contains(
                getEventInfoByType(EVENT_CONST_TYPES.NOISE).renderAttrs
                  .className
              ),
            },
          };
        }

        if (selectionMarkerType === SELECTION_MARKER_TYPE.TERMINATION) {
          if (
            _selectionStrip.ONSET.clickedWaveformIndex !== undefined &&
            _selectionStrip.TERMINATION.clickedWaveformIndex !== undefined &&
            representativeWaveformIndex + clickedWaveformIndex <
              _selectionStrip.ONSET.representativeWaveformIndex +
                _selectionStrip.ONSET.clickedWaveformIndex
          ) {
            _selectionStrip[SELECTION_MARKER_TYPE.ONSET] = {
              selectionMarkerType: SELECTION_MARKER_TYPE.ONSET,
              representativeTimestamp: timestamp,
              representativeWaveformIndex,
              clickedWaveformIndex: Math.round(clickedWaveformIndex),
              extraParam: {
                isNoise: event.target.classList.contains(
                  getEventInfoByType(EVENT_CONST_TYPES.NOISE).renderAttrs
                    .className
                ),
              },
            };
          } else {
            _selectionStrip[SELECTION_MARKER_TYPE.TERMINATION] = {
              selectionMarkerType,
              representativeTimestamp: timestamp,
              representativeWaveformIndex,
              clickedWaveformIndex: Math.round(clickedWaveformIndex),
              extraParam: {
                isNoise: event.target.classList.contains(
                  getEventInfoByType(EVENT_CONST_TYPES.NOISE).renderAttrs
                    .className
                ),
              },
            };
          }
        }

        if (
          _selectionStrip.ONSET.clickedWaveformIndex +
            _selectionStrip.ONSET.representativeWaveformIndex >
          _selectionStrip.TERMINATION.clickedWaveformIndex +
            _selectionStrip.TERMINATION.representativeWaveformIndex
        ) {
          const swap = _selectionStrip.ONSET;
          _selectionStrip.ONSET = _selectionStrip.TERMINATION;
          _selectionStrip.ONSET.selectionMarkerType =
            SELECTION_MARKER_TYPE.ONSET;

          _selectionStrip.TERMINATION = swap;
          _selectionStrip.TERMINATION.selectionMarkerType =
            selectionMarkerType = SELECTION_MARKER_TYPE.TERMINATION;
        }

        this.renderSelectionMarker(
          clickedXAxisPixel,
          HIGHCHART_UNIT.PIXEL,
          selectionMarkerType
        );

        events.subscribe(
          setSelectionStrip.bind(this, {
            selectionMarkerType,
            representativeTimestamp: timestamp,
            representativeWaveformIndex,
            clickedWaveformIndex: Math.round(clickedWaveformIndex),
            extraParam: {
              isNoise: event.target.classList.contains(
                getEventInfoByType(EVENT_CONST_TYPES.NOISE).renderAttrs
                  .className
              ),
            },
          })
        );

        function _isShiftClick() {
          return (
            (shiftKey &&
              onsetRepresentativeTimestamp !== undefined &&
              terminationRepresentativeTimestamp === undefined) ||
            (shiftKey &&
              onsetRepresentativeTimestamp !== undefined &&
              terminationRepresentativeTimestamp !== undefined)
          );
        }

        function _isClick() {
          return (
            (!shiftKey &&
              onsetRepresentativeTimestamp !== undefined &&
              terminationRepresentativeTimestamp !== undefined) ||
            (!shiftKey && terminationRepresentativeTimestamp === undefined)
          );
        }
      },
      /**
       * render selection marker
       *
       * @param {number} 하이차트 x축 위치(pixel)
       * @param {string} HIGHCHART_UNIT
       * @param {string} SELECTION_MARKER_TYPE
       * @param {boolean} withRepresentativeSelectMarker 대표 Strip 선택시 보여지는 Marker 의 render 여부
       */
      renderSelectionMarker(
        xAxis,
        type = HIGHCHART_UNIT.PIXEL,
        selectionMarkerType,
        withRepresentativeSelectMarker = false
      ) {
        const plotHeight = chartInst.plotHeight;
        const strokeWidth = 1;
        const chartPlotHeight = chartInst.plotHeight;
        const offset_yAxis = 0.5;
        const zIndex = 10;

        const xAxisPixel =
          type === HIGHCHART_UNIT.PIXEL
            ? xAxis
            : chartInst.xAxis[0].toPixels(xAxis);

        const selectionMarkerVerticalInst = chartInst.renderer
          .path()
          .attr({
            id: 'huinno-selectionMarker-vertical-line',
            d: `M${xAxisPixel} ${offset_yAxis + 16} V${chartPlotHeight + 18}`,
            clickedChartX: xAxisPixel,
            stroke: theme.color.PRIMARY_BLUE,
            'stroke-width': strokeWidth,
            zIndex,
          })
          .add();

        selectionMarkerVerticalInst?.element &&
          selectionMarkerVerticalInst?.element?.addEventListener(
            'click',
            (e) => {
              e.stopPropagation();
            }
          );

        const selectionMarkerTopInst = chartInst.renderer
          .path()
          .attr({
            class: 'huinno-selectionMarker-top',
            d: 'M8 2L4 6L-2.62268e-07 2L-3.93402e-07 9.3251e-07L8 9.53674e-07L8 2Z',
            transform: `translate(${xAxisPixel - 4}, ${14.5})`,
            fill: theme.color.PRIMARY_BLUE,
            stroke: theme.color.PRIMARY_BLUE,
            'stroke-width': strokeWidth,
            zIndex,
          })
          .add();

        const selectionMarkerBottomInst = chartInst.renderer
          .path()
          .attr({
            class: 'huinno-selectionMarker-bottom',
            d: 'M2.00115e-07 52L4 48L8 52L8 54L-3.45442e-07 54L2.00115e-07 52Z',
            transform: `translate(${xAxisPixel - 4}, ${plotHeight - 34.5})`,
            fill: theme.color.PRIMARY_BLUE,
            stroke: theme.color.PRIMARY_BLUE,
            'stroke-width': strokeWidth,
            zIndex,
          })
          .add();

        const _selectionMarkerUuid =
          CHART_EDIT_CONST.SELECTION_MARKER + selectionMarkerType;

        if (!window[_selectionMarkerUuid]) {
          window[_selectionMarkerUuid] = [];
        }

        window[_selectionMarkerUuid].push(
          ...[
            selectionMarkerVerticalInst,
            selectionMarkerTopInst,
            selectionMarkerBottomInst,
          ]
        );

        if (withRepresentativeSelectMarker) {
          const selectMarker =
            this.renderRepresentativeSelectMarker(xAxisPixel);
          window[_selectionMarkerUuid].push(selectMarker);
        }
      },
      /**
       * 대표 Strip 클릭 시 제공되는 Marker
       * @param {number} xAxisPixel 하이차트 x축 위치(pixel)
       */
      renderRepresentativeSelectMarker: (xAxisPixel) => {
        const title = 'Select Strip';

        const defaultAttrs = {
          // svg 설정 option
          'stroke-width': 0.5,
          stroke: theme.color.PRIMARY_BLUE,
          r: 2,
          fill: theme.color.PRIMARY_BLUE,
          zIndex: 8,
          padding: -0.5, // XXX: 준호 - 음수 값으로 인해 렌더될때 ❗️Highcharts Error❗️ 가 발생되지만, 정상적으로 동작함
          paddingRight: 2.5,
          paddingLeft: 2.5,
          // ⭐️svg 안의 style 설정 option
          style: {
            fontFamily: 'Spoqa Han Sans Neo',
            color: theme.color.WHITE,
            fontSize: '11px',
            lineHeight: '130%',
            fontWeight: 500,
            cursor: 'default',
          },
        };

        const selectMarker = chartInst.renderer
          .button(
            title,
            0, // x
            0, // y
            () => {},
            defaultAttrs,
            defaultAttrs,
            defaultAttrs,
            defaultAttrs
          )
          .attr({
            class: `representative-select-strip-marker-button-${xAxisPixel}`,
          })
          .add();

        const halfOfPatientTriggerWidth = selectMarker.width / 2;

        // ⭐️ selectMarker x축 위치는 아래 align의 x,y property에 의해서 결정됩니다.
        selectMarker.align(
          {
            align: 'left',
            x: xAxisPixel - halfOfPatientTriggerWidth, // ⭐️ patient box 가로 중심이 patient point(ECG point)로 오게 하는 과정.
            y: 0, // ⭐️ 10 char와 patient trigger button의 간격
          },
          false,
          null
        );

        return selectMarker;
      },
      /**
       * render selection highlight
       *
       * @param {string} STRIP_TYPE
       * @param {{ number, number }} selection strip의 selection highlight 영역의 하이차트 waveformIndex
       */
      renderSelectionHighlight(
        stripType,
        { onsetWaveformIndex, terminationWaveformIndex }
      ) {
        const onsetXpoint = chartInst.xAxis[0].toPixels(onsetWaveformIndex);
        const terminationXpoint = chartInst.xAxis[0].toPixels(
          terminationWaveformIndex
        );

        const xAxis = chartInst.xAxis[0];
        const yAxis = chartInst.yAxis[0];

        let leftPosition;
        let topPosition = yAxis.top;
        let rectWidth = xAxis.width;
        let rectHieght = yAxis.height;

        switch (stripType) {
          case STRIP_TYPE.SINGLE_LINE:
            leftPosition = onsetXpoint;
            rectWidth = terminationXpoint - onsetXpoint;
            break;
          case STRIP_TYPE.MULTI_LINE.ONSET:
            leftPosition = onsetXpoint;
            rectWidth = rectWidth - onsetXpoint;
            break;
          case STRIP_TYPE.MULTI_LINE.MIDDLE:
            leftPosition = 0;
            rectWidth = rectWidth;
            break;
          case STRIP_TYPE.MULTI_LINE.TERMINATION:
            leftPosition = 0;
            rectWidth = terminationXpoint;
            break;
        }

        const _selectionHighLightUuid =
          CHART_EDIT_CONST.SELECTION_HIGHLIGHT + timestamp;
        window[_selectionHighLightUuid] &&
          window[_selectionHighLightUuid]?.destroy();

        chartInst._selectionHightlight = chartInst.renderer
          .rect(
            leftPosition, // x
            topPosition, // y
            rectWidth, // width
            rectHieght // height
          )
          .attr({
            id: 'huinno-selectionStrip-' + timestamp,
            'stroke-width': 0,
            fill: theme.color.PRIMARY_BLUE_FOR_SELECTION,
            'stroke-dashoffset': -300,
            zIndex: 9,
          })
          .add();

        if (!window[CHART_EDIT_CONST.SELECTION_HIGHLIGHT]) {
          window[CHART_EDIT_CONST.SELECTION_HIGHLIGHT] = [];
        }
        window[CHART_EDIT_CONST.SELECTION_HIGHLIGHT].push(
          chartInst._selectionHightlight
        );
      },
      /**
       * 10s Strip Render하기 위한 사전 작업
       *
       * @param {event} 하이차트 클릭 이벤트 객체
       * @param {number} representativeCenterTimeStamp
       * @param {number} representativeCenterWaveformIndex
       */
      renderTenSecStrip(
        event,
        representativeCenterTimeStamp,
        representativeCenterWaveformIndex
      ) {
        try {
          let tenSecStripTimestamp = timestamp || arguments[1];
          const maxXaxis = CHART_CONST.XAXIS_MAX;
          const { clickedWaveformIndex } = event;
          const MAXSECOND = 30;

          const xAxisLocationScale = maxXaxis / MAXSECOND;
          const fiveSecWaveformIndexScale = xAxisLocationScale * 5;

          const startTenSecStripWaveformIndex =
            clickedWaveformIndex - fiveSecWaveformIndexScale;
          const endTenSecStripWaveformIndex =
            clickedWaveformIndex + fiveSecWaveformIndexScale;

          _init.call(this);

          let mainTenSecStrip = {
            type: TEN_SEC_STRIP.TYPE.MAIN,
            position: TEN_SEC_STRIP.POSITION.NONE,
            representativeTimestamp: representativeCenterTimeStamp,
            onsetWaveformIndex: startTenSecStripWaveformIndex,
            terminationWaveformIndex: endTenSecStripWaveformIndex,
          };

          let extraTenSecStrip = {
            type: TEN_SEC_STRIP.TYPE.EXTRA,
            position: TEN_SEC_STRIP.POSITION.NONE,
            representativeTimestamp: undefined,
            onsetWaveformIndex: undefined,
            terminationWaveformIndex: undefined,
          };

          // setting - mainTenSecStrip
          if (startTenSecStripWaveformIndex < 0) {
            mainTenSecStrip.onsetWaveformIndex = 0;
            mainTenSecStrip.terminationWaveformIndex =
              endTenSecStripWaveformIndex;
          } else if (endTenSecStripWaveformIndex > maxXaxis) {
            mainTenSecStrip.onsetWaveformIndex = startTenSecStripWaveformIndex;
            mainTenSecStrip.terminationWaveformIndex = maxXaxis;
          }

          // setting - extraTenSecStrip
          if (startTenSecStripWaveformIndex < 0) {
            Object.assign(extraTenSecStrip, {
              position: TEN_SEC_STRIP.POSITION.PREV,
              representativeTimestamp:
                representativeCenterTimeStamp - 1000 * 30,
              onsetWaveformIndex: maxXaxis + startTenSecStripWaveformIndex,
              terminationWaveformIndex: maxXaxis,
            });
          } else if (endTenSecStripWaveformIndex > maxXaxis) {
            Object.assign(extraTenSecStrip, {
              position: TEN_SEC_STRIP.POSITION.NEXT,
              representativeTimestamp:
                representativeCenterTimeStamp + 1000 * 30,
              onsetWaveformIndex: 0,
              terminationWaveformIndex: endTenSecStripWaveformIndex - maxXaxis,
            });
          }

          const tenSecStripParam = {
            representativeCenterTimeStamp: representativeCenterTimeStamp,
            representativeCenterWaveformIndex:
              representativeCenterWaveformIndex,
            centerWaveformIndex: clickedWaveformIndex,
            [TEN_SEC_STRIP.TYPE.MAIN]: mainTenSecStrip,
            [TEN_SEC_STRIP.TYPE.EXTRA]: extraTenSecStrip,
          };

          events.subscribe(setTenSecStrip.bind(this, tenSecStripParam));

          return window[CHART_EDIT_CONST.MAIN_TENSEC_STRIP];

          function _init() {
            this.removeTenSecStrip();
          }
        } catch (error) {
          console.error(error);
        }
      },
      /**
       * render 10s Strip
       *
       * @param {TENSEC_STRIP.TYPE} type
       * @param {TENSEC_STRIP.POSITION} position
       * @param {number} timestamp
       * @param {number} timestamp
       * @param {number} timestamp
       */
      _renderTenSecStrip(
        type,
        position,
        tenSecStripRepresentativeTimestamp,
        tenSecStripOnsetWaveformIndex,
        tenSecStripTerminationWaveformIndex
      ) {
        try {
          const { y: plotBoxY, height: plotBoxHeight } = chartInst.plotBox;
          let startXLocation, topYaxis, width, heightYaxis;
          startXLocation = tenSecStripOnsetWaveformIndex;
          width = chartInst.xAxis[0].toPixels(
            tenSecStripTerminationWaveformIndex - tenSecStripOnsetWaveformIndex
          );
          topYaxis = plotBoxY;
          heightYaxis = plotBoxHeight;

          const xPixel = chartInst.xAxis[0].toPixels(startXLocation);
          const tenSecStripInst = chartInst.renderer
            .rect(xPixel, topYaxis, width, heightYaxis)
            .attr({
              id: 'huinno-tenSecStrip',
              stroke: theme.color.PRIMARY_BLUE,
              'stroke-width': 2,
              zIndex: 11,
            })
            .add();
          tenSecStripInst.timestamp = timestamp;

          if (type === TEN_SEC_STRIP.TYPE.MAIN) {
            const mainTenSecSTripInst =
              window[CHART_EDIT_CONST.MAIN_TENSEC_STRIP];
            mainTenSecSTripInst?.element && mainTenSecSTripInst.destroy();
            delete window[CHART_EDIT_CONST.MAIN_TENSEC_STRIP];

            window[CHART_EDIT_CONST.MAIN_TENSEC_STRIP] = tenSecStripInst;
          } else if (type === TEN_SEC_STRIP.TYPE.EXTRA) {
            const extraTenSecStripInst =
              window[CHART_EDIT_CONST.EXTRA_TENSEC_STRIP];
            extraTenSecStripInst?.element && extraTenSecStripInst.destroy();
            delete window[CHART_EDIT_CONST.EXTRA_TENSEC_STRIP];

            window[CHART_EDIT_CONST.EXTRA_TENSEC_STRIP] = tenSecStripInst;
          }
        } catch (error) {
          console.error(error);
        }
      },
      /**
       * redner report representative strip
       *
       * @param {number} onsetMs
       * @param {number} terminationMs
       * @param {number} onset
       * @param {number} termination
       * @param {REPORT_REPRESENTATIVE_STRIP.TYPE} type
       */
      renderRepresentativeReportStrip(
        onsetMs,
        terminationMs,
        onset,
        termination,
        type
      ) {
        const { max: maxXaxis } = chartInst.xAxis[0];
        const { top: topYaxis, height: heightYaxis } = chartInst.yAxis[0];
        const wholeLineSecXAxisLocationScale = maxXaxis;

        let startPoint, width;
        if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.WHOLELINE) {
          startPoint = 0;
          width = chartInst.xAxis[0].toPixels(wholeLineSecXAxisLocationScale);
        } else if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.MULTILINE.BEGIN) {
          startPoint = chartInst.xAxis[0].toPixels(onset - onsetMs);
          width = chartInst.xAxis[0].toPixels(
            wholeLineSecXAxisLocationScale - (onset - onsetMs)
          );
        } else if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.MULTILINE.LAST) {
          startPoint = 0;
          width = chartInst.xAxis[0].toPixels(termination - onsetMs);
        } else if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.MULTILINE.MID) {
          startPoint = 0;
          width = chartInst.xAxis[0].toPixels(wholeLineSecXAxisLocationScale);
        } else if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.ONELINE) {
          startPoint = chartInst.xAxis[0].toPixels(onset - onsetMs);
          width = chartInst.xAxis[0].toPixels(
            wholeLineSecXAxisLocationScale -
              (terminationMs - termination) -
              (onset - onsetMs)
          );
        }

        const tenSecStripInst = chartInst.renderer
          .rect(startPoint + 1, topYaxis, width - 2, heightYaxis)
          .attr({
            id: 'huinno-representative-strip',
            stroke: theme.color.PRIMARY_BLUE,
            'stroke-width': 2,
            zIndex: 11,
          })
          .add();

        if (
          Array.isArray(window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP])
        ) {
          window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP].push(
            tenSecStripInst
          );
        } else {
          window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP] = [
            tenSecStripInst,
          ];
        }
      },
      /**
       *
       * move 10s strip
       * @param {TENSEC_STRIP.MOVE_TYPE} type
       * @param {timestamp} onsetRepresentativeTimestamp
       * @param {number} representativeCenterWaveformIndex
       * @param {number} centerWaveformIndex
       * @param {number} sec
       */
      moveTenSecStrip(
        type,
        onsetRepresentativeTimestamp,
        representativeCenterWaveformIndex = undefined,
        centerWaveformIndex,
        sec
      ) {
        let movedRepresentativeCenterTimeStamp, movedOnsetWaveformIndex;

        if (type === TEN_SEC_STRIP.MOVE_TYPE.PREV) {
          const nextWaveformIndex = centerWaveformIndex - 250 * sec; // 250: 1초를 그릴때 필요한 waveformIndex 개수, 10: 10초

          if (nextWaveformIndex < 0) {
            movedRepresentativeCenterTimeStamp =
              onsetRepresentativeTimestamp - 1000 * 30; // 1000 * 30: 1초 * 30초
            movedOnsetWaveformIndex = CHART_CONST.XAXIS_MAX + nextWaveformIndex;
          } else {
            movedRepresentativeCenterTimeStamp = onsetRepresentativeTimestamp;
            movedOnsetWaveformIndex = nextWaveformIndex;
          }
        } else if (type === TEN_SEC_STRIP.MOVE_TYPE.NEXT) {
          const nextWaveformIndex = centerWaveformIndex + 250 * 10;

          if (nextWaveformIndex > CHART_CONST.XAXIS_MAX) {
            movedRepresentativeCenterTimeStamp =
              onsetRepresentativeTimestamp + 1000 * 30;
            movedOnsetWaveformIndex = Math.abs(
              CHART_CONST.XAXIS_MAX - nextWaveformIndex
            );
          } else {
            movedRepresentativeCenterTimeStamp = onsetRepresentativeTimestamp;
            movedOnsetWaveformIndex = nextWaveformIndex;
          }
        }

        this.renderTenSecStrip(
          { clickedWaveformIndex: movedOnsetWaveformIndex },
          movedRepresentativeCenterTimeStamp,
          (movedRepresentativeCenterTimeStamp - recordingStartMs) / 4
        );

        // events.subscribe(
        //   setSelectionStrip.bind(this, {
        //     selectionMarkerType: SELECTION_MARKER_TYPE.ONSET,
        //     representativeTimestamp: moveOnsetTimeStamp,
        //     waveformIndex: moveOnsetWaveformIndex,
        //   })
        // );
      },
      /**
       * 차트에 그려진 selection marker중 제거할 객체를 받아 제거
       *
       * @param {Object} selection Marker 객체
       */
      removeSelectionMarker(selectionMarkerInst) {
        try {
          if (!selectionMarkerInst || selectionMarkerInst.length === 0) return;

          while (selectionMarkerInst.length > 0) {
            const selectionMarkerInstOne = selectionMarkerInst.shift();
            selectionMarkerInstOne.element && selectionMarkerInstOne.destroy();
          }
        } catch (error) {
          // 이미 삭제된 요소의 삭제 처리임
        }
      },
      /**
       * 차트에 그려진 selection marker 모두 제거
       */
      removeSelectionMarkerAll() {
        this.removeSelectionMarker(
          window[
            CHART_EDIT_CONST.SELECTION_MARKER + SELECTION_MARKER_TYPE.ONSET
          ]
        );
        this.removeSelectionMarker(
          window[
            CHART_EDIT_CONST.SELECTION_MARKER +
              SELECTION_MARKER_TYPE.TERMINATION
          ]
        );
      },
      /**
       * 차트에 그려진 onset selection marker 제거
       */
      removeOnsetSelectionMarker() {
        this.removeSelectionMarker(
          window[
            CHART_EDIT_CONST.SELECTION_MARKER + SELECTION_MARKER_TYPE.ONSET
          ]
        );
      },
      /**
       * 차트에 그려진 termination selection marker 제거
       */
      removeTerminationSelectionMarker() {
        this.removeSelectionMarker(
          window[
            CHART_EDIT_CONST.SELECTION_MARKER +
              SELECTION_MARKER_TYPE.TERMINATION
          ]
        );
      },
      /**
       * selection highlight 제거
       */
      removeSelectionHighlight() {
        try {
          const _selectionStripUuid = CHART_EDIT_CONST.SELECTION_HIGHLIGHT;
          window[_selectionStripUuid] && window[_selectionStripUuid]?.destroy();
        } catch (error) {
          // 이미 삭제된 요소의 삭제 처리임
        }
      },
      /**
       * multi line으로 렌더된 seleciton highlight 제거
       */
      removeSelectionHighlightAll() {
        try {
          const _selectionStripArr =
            window[CHART_EDIT_CONST.SELECTION_HIGHLIGHT];

          if (!Array.isArray(_selectionStripArr)) return;

          while (_selectionStripArr.length > 0) {
            const one = _selectionStripArr.shift();
            one.element && one.destroy();
          }
        } catch (error) {
          // 이미 삭제된 요소의 삭제 처리임
        }
      },
      /**
       * 10s strip 제거
       */
      removeTenSecStrip() {
        try {
          const tenSecStripInst = window[CHART_EDIT_CONST.MAIN_TENSEC_STRIP];
          const extraTenSecStripInst =
            window[CHART_EDIT_CONST.EXTRA_TENSEC_STRIP];

          tenSecStripInst?.element && tenSecStripInst.destroy();
          extraTenSecStripInst?.element && extraTenSecStripInst.destroy();

          delete window[CHART_EDIT_CONST.MAIN_TENSEC_STRIP];
          delete window[CHART_EDIT_CONST.EXTRA_TENSEC_STRIP];
        } catch (error) {
          // 이미 삭제된 요소의 삭제 처리임
        }
      },
      /**
       * report representative strip 제거
       */
      removeRepresentativeReportStrip() {
        try {
          if (
            !Array.isArray(window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP])
          )
            return;

          while (
            window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP].length !== 0
          ) {
            window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP]
              .pop()
              .destroy();
          }
        } catch (error) {
          // 이미 삭제된 요소의 삭제 처리임
        }
      },
      /**
       * chartEdit에서 render한 모든 것들 제거
       */
      removeAll() {
        this.removeSelectionMarkerAll();
        this.removeSelectionHighlightAll();
        this.removeTenSecStrip();
        this.removeRepresentativeReportStrip();
      },
      /**
       *
       * @param {event} 하이차트 클릭 이벤트 객체
       * @param {[Timestamp, Timestamp]} onset, termination selection marker timestamp
       * @returns boolean
       */
      _validationSelectionMarker(event, [onsetInst, terminationInst]) {
        const { shiftKey } = event;
        let result = true;

        if (!onsetInst) {
          if (shiftKey) {
            return (result = false), result;
          } else {
            return result;
          }
        }

        return result;
      },
    };
  },
};

/**
 * ECG 데이터를 시각화한 Highcharts 의 chartX 값을 **localWaveformIndex 로 변환**
 *
 * @param {number} chartX 렌더된 Highcharts Element 의 X 축 좌표값
 * @param {{x:number, y:number, width:number, height:number}} plotBox 렌더된 Highcharts Inst. 의 property 중 plotBox
 * @param {number} waveformLength 렌더된 Highcharts Element 에 사용된 ECG Waveform 길이
 * @return {number} chartX 에 해당하는 **localWaveformIndex**, 오류 시 -1
 */
export function transformSystemChartXToWaveformIndex(
  chartX,
  plotBox,
  waveformLength
) {
  try {
    const aWaveformWidth = plotBox.width / waveformLength;
    const plotX = chartX - plotBox.x;
    return Math.round(plotX / aWaveformWidth);
  } catch (error) {
    console.error('transformSystemChartXToWaveformIndex', error);
  }
  return -1;
}

/**
 * localWaveformIndex 값을 ECG 데이터를 시각화한 Highcharts 의 **chartX 로 변환**
 *
 * @param {number} localWaveformIndex 렌더된 Highcharts Element 에 사용된 ECG Waveform 중 특정 index
 * @param {{x:number, y:number, width:number, height:number}} plotBox 렌더된 Highcharts Inst. 의 property 중 plotBox
 * @param {number} waveformLength 렌더된 Highcharts Element 에 사용된 ECG Waveform 길이
 * @return {number} localWaveformIndex 에 해당하는 **chartX**, 오류 시 -1
 */
export function transformSystemWaveformIndexToChartX(
  localWaveformIndex,
  plotBox,
  waveformLength
) {
  try {
    const aWaveformWidth = plotBox.width / waveformLength;
    return Math.round(localWaveformIndex * aWaveformWidth + plotBox.x);
  } catch (error) {
    console.error('transformSystemWaveformIndexToChartX', error);
  }
  return -1;
}

export const ECGChartCommonOption = {
  tooltip: {
    enabled: false,
    formatter: function () {
      return `${this.x / 250}sec ${this.y.toFixed(2)} mV`;
    },
  },
  boost: {
    // Need to consider whether use
    // enabled: true,
    useGPUTranslations: true,
    // usePreallocated: true,
  },
  title: null,
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
};

export default ChartUtil;
