import ApiManager from 'network/ApiManager';
import StatusCode from 'network/StatusCode';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PAGE_SIZE, GetInitRequest } from 'util/ClassLabelUtil';

// Actions
// Get ECG Event
const GET_ECG_EVENTS_REQUESTED = 'memo-web/report/GET_ECG_EVENTS_REQUESTED';
const GET_ECG_EVENTS_SUCCEED = 'memo-web/report/GET_ECG_EVENTS_SUCCEED';
const GET_ECG_EVENTS_FAILED = 'memo-web/report/GET_ECG_EVENTS_FAILED';
const GET_ECG_EVENTS_BY_IDS_REQUESTED =
  'memo-web/report/GET_ECG_EVENTS_BY_IDS_REQUESTED';
const GET_REPORT_SELECTED_REQUESTED =
  'memo-web/report/GET_REPORT_SELECTED_REQUESTED';
const GET_REPORT_SELECTED_SUCCEED =
  'memo-web/report/GET_REPORT_SELECTED_SUCCEED';
const GET_REPORT_SELECTED_FAILED = 'memo-web/report/GET_REPORT_SELECTED_FAILED';

// Reducer
const initialState = {
  list: {
    pending: false,
    data: null,
    error: null,
  },
  selected: {
    pending: false,
    data: null,
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Get ECG Events
    case GET_ECG_EVENTS_REQUESTED:
    case GET_ECG_EVENTS_BY_IDS_REQUESTED:
      return {
        ...state,
        list: {
          pending: true,
          data: state.list.data,
          error: null,
        },
      };
    case GET_ECG_EVENTS_SUCCEED:
      return {
        ...state,
        list: {
          pending: false,
          data: action.data,
        },
      };
    case GET_ECG_EVENTS_FAILED:
      return {
        ...state,
        list: {
          pending: false,
          error: action.error,
        },
      };
    // Get Report Selected
    case GET_REPORT_SELECTED_REQUESTED:
      return {
        ...state,
        selected: {
          pending: true,
          data: null,
          error: null,
        },
      };
    case GET_REPORT_SELECTED_SUCCEED:
      return {
        ...state,
        selected: {
          pending: false,
          data: action.data,
        },
      };
    case GET_REPORT_SELECTED_FAILED:
      return {
        ...state,
        selected: {
          pending: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

// Action Creators
// Get ECG Events
export function getEcgEventsRequested(tid, finalDiagnosis, page) {
  return {
    type: GET_ECG_EVENTS_REQUESTED,
    tid,
    finalDiagnosis,
    page,
  };
}
function getEcgEventsSucceed(data) {
  return {
    type: GET_ECG_EVENTS_SUCCEED,
    data,
  };
}
function getEcgEventsFailed(error) {
  return {
    type: GET_ECG_EVENTS_FAILED,
    error,
  };
}
export function getEcgEventsByIdsRequested(tid, finalDiagnosis, ids) {
  return {
    type: GET_ECG_EVENTS_BY_IDS_REQUESTED,
    tid,
    finalDiagnosis,
    ids,
  };
}
// Get Report Selected
export function getReportSelectedRequested(reportId) {
  return {
    type: GET_REPORT_SELECTED_REQUESTED,
    reportId,
  };
}
function getReportSelectedSucceed(data) {
  return {
    type: GET_REPORT_SELECTED_SUCCEED,
    data,
  };
}
function getReportSelectedFailed(error) {
  return {
    type: GET_REPORT_SELECTED_FAILED,
    error,
  };
}

/**
 * 검사에서 부정맥으로 판단되는 strip 조회. 단, bookmark 된것만
 * @param {*} action
 * @returns
 */
function* getEcgEvents(action) {
  try {
    // bookmark 된 strip 만 검색
    const { status, data } = yield call(ApiManager.getEventsList, {
      customerBookmarkLevel: 1,
      withEcg: true,
      pageSize: PAGE_SIZE,
      tid: action.tid,
      finalDiagnosis: action.finalDiagnosis,
      page: action.page,
      checkRecommended: true,
    });

    const { error } = data;

    const result = {
      count: data.count,
      results: data.results,
    };
    yield put(getEcgEventsSucceed(result));
    return;
  } catch (error) {
    yield put(getEcgEventsFailed(error));
  }
}

function* getEcgEventsByIds(action) {
  try {
    // bookmark 된 strip 중 특정 id 목록에 해당하는 event 검색
    const { status, data } = yield call(ApiManager.getEventsList, {
      customerBookmarkLevel: 1,
      withEcg: true,
      pageSize: action.ids.length,
      tid: action.tid,
      finalDiagnosis: action.finalDiagnosis,
      page: 1,
      id: action.ids.join(','),
      checkRecommended: true,
    });
    const { error } = data;

    yield put(getEcgEventsSucceed(data));
  } catch (error) {
    yield put(getEcgEventsFailed({ status: error }));
  }
}

function* getReportSelected(action) {
  try {
    const { status, data } = yield call(ApiManager.readReportSelected, {
      reportId: action.reportId,
    });

    const { error, result } = data;
    const initRequest = GetInitRequest();

    const snakeObject = {};
    for (let camel in result) {
      snakeObject[
        camel
          .split(/(?=[A-Z])/)
          .join('_')
          .toLowerCase()
      ] = result[camel];
    }

    for (let key in initRequest) {
      if (!(key in snakeObject)) snakeObject[key] = initRequest[key];
    }
    yield put(getReportSelectedSucceed(snakeObject));
  } catch (error) {
    yield put(getReportSelectedFailed({ status: error }));
  }
}

export function* saga() {
  yield takeLatest(GET_ECG_EVENTS_REQUESTED, getEcgEvents);
  yield takeLatest(GET_ECG_EVENTS_BY_IDS_REQUESTED, getEcgEventsByIds);
  yield takeLatest(GET_REPORT_SELECTED_REQUESTED, getReportSelected);
}
