import { call, put, takeLatest } from 'redux-saga/effects';
import ApiManager from 'network/ApiManager';
import Const from 'constant/Const';
import { GetClassKeys, GetClassInfo } from 'util/ClassLabelUtil';

// Actions
// Fetch (Multiple)
const FETCH_EVENTS_REQUESTED = 'memo-web/events/FETCH_EVENTS_REQUESTED';
const FETCH_EVENTS_SUCCEED = 'memo-web/events/FETCH_EVENTS_SUCCEED';
const FETCH_EVENTS_FAILED = 'memo-web/events/FETCH_EVENTS_FAILED';
// Fetch (Single)
const FETCH_EVENT_REQUESTED = 'memo-web/events/FETCH_EVENT_REQUESTED';
const FETCH_EVENT_SUCCEED = 'memo-web/events/FETCH_EVENT_SUCCEED';
const FETCH_EVENT_FAILED = 'memo-web/events/FETCH_EVENT_FAILED';
// Fetch (Multiple by bookmared classes)
const FETCH_BOOKMARKED_EVENT_REQUESTED =
  'memo-web/events/FETCH_BOOKMARKED_EVENT_REQUESTED';
const FETCH_BOOKMARKED_EVENT_SUCCEED =
  'memo-web/events/FETCH_BOOKMARKED_EVENT_SUCCEED';
const FETCH_BOOKMARKED_EVENT_FAILED =
  'memo-web/events/FETCH_BOOKMARKED_EVENT_FAILED';
// Update
const UPDATE_EVENT_REQUESTED = 'memo-web/events/UPDATE_EVENT_REQUESTED';
const UPDATE_EVENT_SUCCEED = 'memo-web/events/UPDATE_EVENT_SUCCEED';
const UPDATE_EVENT_FAILED = 'memo-web/events/UPDATE_EVENT_FAILED';
// Confirm
const CONFIRM_EVENT_REQUESTED = 'memo-web/events/CONFIRM_EVENT_REQUESTED';
const CONFIRM_EVENT_SUCCEED = 'memo-web/events/CONFIRM_EVENT_SUCCEED';
const CONFIRM_EVENT_FAILED = 'memo-web/events/CONFIRM_EVENT_FAILED';
// View
const SET_SELECTED_DROPDOWN_ITEM_INDEX =
  'memo-web/events/SET_SELECTED_DROPDOWN_ITEM_INDEX';
const SET_SELECTED_EVENT_ITEM_INDEX =
  'memo-web/events/SET_SELECTED_EVENT_ITEM_INDEX';
const SET_SHOW_UNCONFIRMED_CHECKED =
  'memo-web/events/SET_SHOW_UNCONFIRMED_CHECKED';
const SET_SHOW_BOOKMARKED_CHECKED =
  'memo-web/events/SET_SHOW_BOOKMARKED_CHECKED';

// Reducer
const initialState = {
  fetch: {
    pending: false,
    count: 0,
    data: [],
    error: null,
  },
  fetchItem: {
    pending: false,
    data: null,
    error: null,
  },
  fetchItems: {
    pending: false,
    data: null,
    error: null,
  },
  updateItem: {
    pending: false,
    data: null,
    error: null,
  },
  confirmItem: {
    pending: false,
    data: null,
    error: null,
  },
  view: {
    ecgTestId: '',
    diagnosisDict: null,
    dropdownItemList: [],
    selectedDropdownItemIndex: 0,
    eventItemList: [],
    selectedEventItemIndex: 0,
    isShowUnconfirmedChecked: false,
    isShowBookmarkedChecked: false,
  },
};

function getDiagnosisDictFromEvents(eventItems) {
  let diagnosisDict = {
    ALL: eventItems.length,
    NORMAL: 0,
    APC: 0,
    VPC: 0,
    NOISE: 0,
    AV_BLOCK: 0,
    AF: 0,
    PAUSE: 0,
    OTHERS: 0,
    SVT: 0,
    VT: 0,
    LEAD_OFF: 0,
  };
  eventItems.forEach((eventItem) => {
    switch (eventItem.finalDiagnosis) {
      case Const.DIAGNOSIS_TYPE.NORMAL:
        diagnosisDict.NORMAL++;
        break;
      case Const.DIAGNOSIS_TYPE.APC:
        diagnosisDict.APC++;
        break;
      case Const.DIAGNOSIS_TYPE.VPC:
        diagnosisDict.VPC++;
        break;
      case Const.DIAGNOSIS_TYPE.AF:
        diagnosisDict.AF++;
        break;
      case Const.DIAGNOSIS_TYPE.OTHERS:
        diagnosisDict.OTHERS++;
        break;
      case Const.DIAGNOSIS_TYPE.NOISE:
        diagnosisDict.NOISE++;
        break;
      case Const.DIAGNOSIS_TYPE.AV_BLOCK:
        diagnosisDict.AV_BLOCK++;
        break;
      case Const.DIAGNOSIS_TYPE.SVT:
        diagnosisDict.SVT++;
        break;
      case Const.DIAGNOSIS_TYPE.VT:
        diagnosisDict.VT++;
        break;
      case Const.DIAGNOSIS_TYPE.PAUSE:
        diagnosisDict.PAUSE++;
        break;
      case Const.DIAGNOSIS_TYPE.LEAD_OFF:
        diagnosisDict.LEAD_OFF++;
        break;
      default:
        break;
    }
  });

  return diagnosisDict;
}

function generateDropdownItemList(diagnosisDict) {
  delete diagnosisDict['Lead Off'];
  const dropdownItemList = Object.keys(diagnosisDict).map((diagnosisKey) => {
    const diagnosisCount = diagnosisDict[diagnosisKey];
    return {
      label: `${
        Const.CLASSIFICATION[diagnosisKey].label
      } (${diagnosisCount.toLocaleString()})`,
      value: Const.DIAGNOSIS_TYPE[diagnosisKey],
      disabled: diagnosisCount === 0,
    };
  });
  return dropdownItemList;
}

function generateEventItemList(
  selectedDropdownItem,
  isShowUnconfirmedChecked,
  isShowBookmarkedChecked,
  events
) {
  // eventItem.customerBookmarkLevel, eventItem.partnerWebUserBookmarkLevel type
  // -> 0, 1
  // -> 0: 북마크x, 1: 북마크O

  // Generate filtered event item list
  const eventItemList = events.filter((eventItem) => {
    let bookMarkValue;

    // * eventItem.customerDiagnosis: customer가 수정했는지 여부 확인 flag
    if (eventItem.customerDiagnosis) {
      bookMarkValue = eventItem.customerBookmarkLevel;
    } else {
      bookMarkValue =
        eventItem.customerBookmarkLevel ||
        eventItem.partnerWebUserBookmarkLevel;
    }

    if (selectedDropdownItem && selectedDropdownItem.value !== undefined) {
      return (
        eventItem.finalDiagnosis === selectedDropdownItem.value &&
        (isShowUnconfirmedChecked ? !eventItem.isConfirmed : true) &&
        (isShowBookmarkedChecked ? (bookMarkValue ? true : false) : true)
      );
    }

    return (
      (isShowUnconfirmedChecked ? !eventItem.isConfirmed : true) &&
      (isShowBookmarkedChecked ? (bookMarkValue ? true : false) : true)
    );
  });

  return eventItemList;
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Fetch (Multiple)
    case FETCH_EVENTS_REQUESTED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: true,
          error: null,
        },
        view: {
          eventItemList: [],
          selectedEventItemIndex: 0,
          dropdownItemList: [],
          selectedDropdownItemIndex: 0,
        },
      };
    case FETCH_EVENTS_SUCCEED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: false,
          count: action.count,
          data: action.data,
        },
        view: {
          ...state.view,
          ecgTestId: action.ecgTestId || state.view.ecgTestId,
          diagnosisDict: action.diagnosisDict,
          dropdownItemList: generateDropdownItemList(action.diagnosisDict),
          eventItemList: generateEventItemList(
            state.view.dropdownItemList[state.view.selectedDropdownItemIndex],
            state.view.isShowUnconfirmedChecked,
            state.view.isShowBookmarkedChecked,
            action.data
          ),
        },
      };
    case FETCH_EVENTS_FAILED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: false,
          error: action.error,
        },
      };
    // Fetch (Single)
    case FETCH_EVENT_REQUESTED:
      return {
        ...state,
        fetchItem: {
          ...state.fetchItem,
          pending: true,
          data: null,
          error: null,
        },
      };
    case FETCH_EVENT_SUCCEED:
      return {
        ...state,
        fetchItem: {
          ...state.fetchItem,
          pending: false,
          data: action.data,
        },
      };
    case FETCH_EVENT_FAILED:
      return {
        ...state,
        fetchItem: {
          ...state.fetchItem,
          pending: false,
          error: action.error,
        },
      };
    // Fetch (Multiple by bookmared classes)
    case FETCH_BOOKMARKED_EVENT_REQUESTED:
      return {
        ...state,
        fetchItems: {
          ...state.fetchItems,
          pending: true,
          data: null,
          error: null,
        },
      };
    case FETCH_BOOKMARKED_EVENT_SUCCEED:
      return {
        ...state,
        fetchItems: {
          ...state.fetchItems,
          pending: false,
          data: action.data,
        },
      };
    case FETCH_BOOKMARKED_EVENT_FAILED:
      return {
        ...state,
        fetchItems: {
          ...state.fetchItems,
          pending: false,
          error: action.error,
        },
      };
    // Update
    case UPDATE_EVENT_REQUESTED:
      return {
        ...state,
        updateItem: {
          ...state.updateItem,
          pending: true,
          error: null,
        },
      };
    case UPDATE_EVENT_SUCCEED:
      return {
        ...state,
        updateItem: {
          ...state.updateItem,
          pending: false,
          data: action.data,
        },
      };
    case UPDATE_EVENT_FAILED:
      return {
        ...state,
        updateItem: {
          ...state.updateItem,
          pending: false,
          error: action.error,
        },
      };
    // Confirm
    case CONFIRM_EVENT_REQUESTED:
      return {
        ...state,
        confirmItem: {
          ...state.confirmItem,
          pending: true,
          error: null,
        },
      };
    case CONFIRM_EVENT_SUCCEED:
      const newEventItemList = [...state.view.eventItemList];
      newEventItemList.splice(
        state.view.selectedEventItemIndex,
        1,
        action.data
      );
      return {
        ...state,
        view: {
          ...state.view,
          eventItemList: newEventItemList,
        },
        confirmItem: {
          ...state.confirmItem,
          pending: false,
          data: action.data,
        },
      };
    case CONFIRM_EVENT_FAILED:
      return {
        ...state,
        confirmItem: {
          ...state.confirmItem,
          pending: false,
          error: action.error,
        },
      };
    // View
    case SET_SELECTED_DROPDOWN_ITEM_INDEX:
      return {
        ...state,
        view: {
          ...state.view,
          eventItemList: generateEventItemList(
            state.view.dropdownItemList[action.index],
            state.view.isShowUnconfirmedChecked,
            state.view.isShowBookmarkedChecked,
            state.fetch.data
          ),
          selectedDropdownItemIndex: action.index,
          selectedEventItemIndex: 0,
        },
      };
    case SET_SELECTED_EVENT_ITEM_INDEX:
      return {
        ...state,
        view: {
          ...state.view,
          selectedEventItemIndex: action.index,
        },
      };
    case SET_SHOW_UNCONFIRMED_CHECKED:
      return {
        ...state,
        view: {
          ...state.view,
          isShowUnconfirmedChecked: action.isChecked,
          eventItemList: generateEventItemList(
            state.view.dropdownItemList[state.view.selectedDropdownItemIndex],
            action.isChecked,
            state.view.isShowBookmarkedChecked,
            state.fetch.data
          ),
          selectedEventItemIndex: 0,
        },
      };
    case SET_SHOW_BOOKMARKED_CHECKED:
      return {
        ...state,
        view: {
          ...state.view,
          isShowBookmarkedChecked: action.isChecked,
          eventItemList: generateEventItemList(
            state.view.dropdownItemList[state.view.selectedDropdownItemIndex],
            state.view.isShowUnconfirmedChecked,
            action.isChecked,
            state.fetch.data
          ),
          selectedEventItemIndex: 0,
        },
      };
    default:
      return state;
  }
}

// Action Creators
// Fetch (Multiple)
export function fetchEventsRequested(ecgTestId) {
  return {
    type: FETCH_EVENTS_REQUESTED,
    ecgTestId: ecgTestId,
  };
}
export function fetchEventsSucceed(count, data, diagnosisDict, ecgTestId) {
  return {
    type: FETCH_EVENTS_SUCCEED,
    count: count,
    data: data,
    diagnosisDict: diagnosisDict,
    ecgTestId: ecgTestId,
  };
}
export function fetchEventsFailed(error) {
  return { type: FETCH_EVENTS_FAILED, error: error };
}
// Fetch (Single)
export function fetchEventRequested(eventId) {
  return {
    type: FETCH_EVENT_REQUESTED,
    eventId: eventId,
  };
}
export function fetchEventSucceed(data) {
  return {
    type: FETCH_EVENT_SUCCEED,
    data: data,
  };
}
export function fetchEventFailed(error) {
  return { type: FETCH_EVENT_FAILED, error: error };
}
// Fetch (Multiple by bookmared classes)
export function fetchBookmaredEventRequested(tid) {
  return {
    type: FETCH_BOOKMARKED_EVENT_REQUESTED,
    tid,
  };
}
function fetchBookmarkedEventSucceed(data) {
  return {
    type: FETCH_BOOKMARKED_EVENT_SUCCEED,
    data: data,
  };
}
function fetchBookmarkedEventFailed(error) {
  return { type: FETCH_BOOKMARKED_EVENT_FAILED, error: error };
}
// Update
export function updateEventRequested(eventId, params) {
  return {
    type: UPDATE_EVENT_REQUESTED,
    eventId: eventId,
    params: params,
  };
}
export function updateEventSucceed(data) {
  return {
    type: UPDATE_EVENT_SUCCEED,
    data: data,
  };
}
export function updateEventFailed(error) {
  return { type: UPDATE_EVENT_FAILED, error: error };
}
// Confirm
export function confirmEventRequested(eventId, params) {
  return {
    type: CONFIRM_EVENT_REQUESTED,
    eventId: eventId,
    params: params,
  };
}
export function confirmEventSucceed(data) {
  return {
    type: CONFIRM_EVENT_SUCCEED,
    data: data,
  };
}
export function confirmEventFailed(error) {
  return { type: CONFIRM_EVENT_FAILED, error: error };
}
// View
export function setSelectedDropdownItemIndex(index) {
  return { type: SET_SELECTED_DROPDOWN_ITEM_INDEX, index: index };
}
export function setSelectedEventItemIndex(index) {
  return { type: SET_SELECTED_EVENT_ITEM_INDEX, index: index };
}
export function setShowUnconfirmedChecked(isChecked) {
  return { type: SET_SHOW_UNCONFIRMED_CHECKED, isChecked: isChecked };
}
export function setShowBookmarkedChecked(isChecked) {
  return { type: SET_SHOW_BOOKMARKED_CHECKED, isChecked: isChecked };
}

// Sagas
function* readEvents(action) {
  try {
    const { status, data } = yield call(ApiManager.readEvents, {
      tid: action.ecgTestId,
      pageSize: 100000,
    });

    const { count, next, previous, results } = data;

    const diagnosisDict = getDiagnosisDictFromEvents(results);
    yield put(
      fetchEventsSucceed(count, results, diagnosisDict, action.ecgTestId)
    );
  } catch (error) {
    yield put(fetchEventsFailed(error));
  }
}

function* readEvent(action) {
  try {
    const { status, data } = yield call(ApiManager.readEvent, action.eventId);

    const { result } = data;

    yield put(fetchEventSucceed(result));
  } catch (error) {
    yield put(fetchEventFailed(error));
  }
}

function* readBookmaredEvent(action) {
  try {
    const results = {};
    for (let className of GetClassKeys()) {
      const classCode = GetClassInfo(className).value;

      const { data } = yield call(ApiManager.readEvents, {
        tid: action.tid,
        pageSize: 1,
        ordering: 'event_timestamp',
        finalDiagnosis: classCode,
        withEcg: true,
        bookmarkLevel: 1,
      });
      if (data.count > 0) {
        results[className] = data.results[0];
      }
    }

    yield put(fetchBookmarkedEventSucceed(results));
  } catch (error) {
    yield put(fetchBookmarkedEventFailed(error));
  }
}

function* updateEvent(action) {
  try {
    const { status, data } = yield call(
      ApiManager.updateEvent,
      action.eventId,
      action.params
    );
    const { result } = data;

    yield put(updateEventSucceed(result));
  } catch (error) {
    yield put(updateEventFailed(error));
  }
}

function* confirmEvent(action) {
  try {
    const { annotation, diagnosis, ecgTestId, bookmarkLevel } = action.params;
    const { status, data } = yield call(
      ApiManager.confirmEvent,
      action.eventId,
      { annotation, diagnosis, bookmarkLevel }
    );

    const { result } = data;
    yield put(confirmEventSucceed(result));

    const { status: readEventsStatus, data: readEventsData } = yield call(
      ApiManager.readEvents,
      { tid: ecgTestId, pageSize: 100000 }
    );
    const { count, next, previous, results } = readEventsData;
    const diagnosisDict = getDiagnosisDictFromEvents(results);

    yield put(fetchEventsSucceed(count, results, diagnosisDict));
  } catch (error) {
    yield put(confirmEventFailed(error));
  }
}

export function* saga() {
  yield takeLatest(FETCH_EVENTS_REQUESTED, readEvents);
  yield takeLatest(FETCH_EVENT_REQUESTED, readEvent);
  yield takeLatest(FETCH_BOOKMARKED_EVENT_REQUESTED, readBookmaredEvent);
  yield takeLatest(UPDATE_EVENT_REQUESTED, updateEvent);
  yield takeLatest(CONFIRM_EVENT_REQUESTED, confirmEvent);
}
