export const handleNextSelectBpm = ({
  _selectHistogram,
  _previousSelectedBpm,
  _selectBeatLabelButtonDataList,
  selectedBeatWaveformIndex,
}) => {
  const indexOfPointCenter = _selectBeatLabelButtonDataList.findIndex(
    (data) => data.xAxisPoint === 1250
  );
  const previousPointOfCenterPoint =
    _selectBeatLabelButtonDataList[indexOfPointCenter - 1]?.xAxisPoint || 0;
  const isSelectedWaveformIndexBetweenPreviousPointAndCenterPoint =
    previousPointOfCenterPoint <= selectedBeatWaveformIndex &&
    selectedBeatWaveformIndex <= 1250;
  let nextSelectBpm = _previousSelectedBpm;

  if (
    _selectHistogram[_previousSelectedBpm] === 1 &&
    isSelectedWaveformIndexBetweenPreviousPointAndCenterPoint
  ) {
    const histogramArray = Object.keys(_selectHistogram).filter(
      (bpm) => _selectHistogram[bpm]
    );
    const currentBpmIndex = histogramArray.findIndex(
      (bpm) => Number(bpm) === _previousSelectedBpm
    );

    nextSelectBpm =
      currentBpmIndex === histogramArray.length - 1
        ? Number(histogramArray[currentBpmIndex - 1])
        : Number(histogramArray[currentBpmIndex + 1]);
  }
  return nextSelectBpm;
};
