import { call, put, takeLatest } from 'redux-saga/effects';
import ApiManager from 'network/ApiManager';
import StatusCode from 'network/StatusCode';
import ResultCode from 'network/ResultCode';
import { resetPasswordByEmailRequested } from 'redux/duck/passwordDuck';
import LocalStorageManager from 'manager/LocalStorageManager';
import LocalStorageKey from 'constant/LocalStorageKey';

// Actions
// CheckEmail
const CHECK_EMAIL_REQUESTED = 'memo-web/auth/CHECK_EMAIL_REQUESTED';
const CHECK_EMAIL_SUCCEED = 'memo-web/auth/CHECK_EMAIL_SUCCEED';
const CHECK_EMAIL_FAILED = 'memo-web/auth/CHECK_EMAIL_FAILED';
// Login
const LOGIN_REQUESTED = 'memo-web/auth/LOGIN_REQUESTED';
const LOGIN_SUCCEED = 'memo-web/auth/LOGIN_SUCCEED';
const LOGIN_FAILED = 'memo-web/auth/LOGIN_FAILED';
// Logout
const LOGOUT_REQUESTED = 'memo-web/auth/LOGOUT_REQUESTED';
const LOGOUT_SUCCEED = 'memo-web/auth/LOGOUT_SUCCEED';
const LOGOUT_FAILED = 'memo-web/auth/LOGOUT_FAILED';
// RefreshAccessToken
const REFRESH_ACCESS_TOKEN_REQUESTED =
  'memo-web/auth/REFRESH_ACCESS_TOKEN_REQUESTED';
// const REFRESH_ACCESS_TOKEN_SUCCEED = 'memo-web/auth/REFRESH_ACCESS_TOKEN_SUCCEED';
// const REFRESH_ACCESS_TOKEN_FAILED = 'memo-web/auth/REFRESH_ACCESS_TOKEN_FAILED';
// ReadMyInformation
const READ_MY_INFORMATION_REQUESTED =
  'memo-web/auth/READ_MY_INFORMATION_REQUESTED';
const READ_MY_INFORMATION_SUCCEED = 'memo-web/auth/READ_MY_INFORMATION_SUCCEED';
const READ_MY_INFORMATION_FAILED = 'memo-web/auth/READ_MY_INFORMATION_FAILED';
// set normal status
const SET_NORMAL_STATUS = 'memo-web/auth/SET_NORMAL_STATUS';
// set login
const SET_SOFT_LOGIN = 'memo-web/auth/SET_SOFT_LOGIN';
// update user info
const UPDATE_USER_INFO = 'memo-web/auth/UPDATE_USER_INFO';

// Reducer
const initialState = {
  pending: false,
  data: null,
  user: null,
  email: {
    pending: false,
    data: null,
    error: null,
  },
  isLoggedIn: false,
  isAdmin: false,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // CheckEmail
    case CHECK_EMAIL_REQUESTED:
      return {
        ...state,
        email: {
          pending: true,
          error: null,
        },
      };
    case CHECK_EMAIL_SUCCEED:
      return {
        ...state,
        email: {
          pending: false,
          data: action.data,
        },
      };
    case CHECK_EMAIL_FAILED:
      return {
        ...state,
        email: {
          pending: false,
          error: action.error,
        },
      };
    // Login
    case LOGIN_REQUESTED:
      return {
        ...state,
        pending: true,
        error: null,
      };
    // case LOGIN_SUCCEED:
    //     return {
    //         ...state,
    //         pending: false,
    //         data: action.data,
    //         user: action.user,
    //         isLoggedIn: true,
    //         isAdmin:
    //             action.user.accessLevel === Const.ACCESS_LEVEL.READ_WRITE,
    //     };
    case LOGIN_FAILED:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    // ReadMyInformation
    case READ_MY_INFORMATION_REQUESTED:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case READ_MY_INFORMATION_SUCCEED:
      return {
        ...state,
        pending: false,
        data: action.data,
        user: action.user,
        // isLoggedIn 값에 따른 Routing 제어를 위해 Fa
        isLoggedIn:
          action.data.code === ResultCode.AUTH_STATUS.CHANGE_PASSWORD_REQUIRED
            ? true
            : false,
        isAdmin: action.user.isHospitalAdmin,
      };
    case READ_MY_INFORMATION_FAILED:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    // Logout
    case LOGOUT_REQUESTED:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case LOGOUT_SUCCEED:
      return {
        ...state,
        pending: false,
        data: null,
        user: null,
        isLoggedIn: false,
      };
    case LOGOUT_FAILED:
      return {
        ...state,
        pending: false,
        isLoggedIn: false,
        error: action.error,
      };
    case SET_NORMAL_STATUS:
      return {
        ...state,
        data: {
          ...(state.data ?? {}),
          code: ResultCode.AUTH_STATUS.NORMAL,
        },
      };
    case SET_SOFT_LOGIN:
      return {
        ...state,
        isLoggedIn: true,
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        user: action.newInfo,
      };
    default:
      return state;
  }
}

// Action Creators
// CheckEmail
export function authCheckEmailRequested(email, isForgot) {
  return { type: CHECK_EMAIL_REQUESTED, email, isForgot };
}
function authCheckEmailSucceed(data) {
  return { type: CHECK_EMAIL_SUCCEED, data };
}
function authCheckEmailFailed(error) {
  return { type: CHECK_EMAIL_FAILED, error: error };
}
// Login
export function authLoginRequested(email, password) {
  return {
    type: LOGIN_REQUESTED,
    email: email,
    password: password,
  };
}
function authLoginSucceed(data, user) {
  return { type: LOGIN_SUCCEED, data: data, user: user };
}
function authLoginFailed(error) {
  return { type: LOGIN_FAILED, error: error };
}
// ReadMyInformation
export function authReadMyInformationRequested(data) {
  return { type: READ_MY_INFORMATION_REQUESTED, data };
}
function authReadMyInformationSucceed(user, data) {
  return { type: READ_MY_INFORMATION_SUCCEED, user, data };
}
function authReadMyInformationFailed(error) {
  return { type: READ_MY_INFORMATION_FAILED, error };
}
// Logout
export function authLogoutRequested() {
  return { type: LOGOUT_REQUESTED };
}
function authLogoutSucceed() {
  return { type: LOGOUT_SUCCEED };
}
function authLogoutFailed(error) {
  return { type: LOGOUT_FAILED, error: error };
}
// RefreshAccessToken
export function authRefreshAccessTokenRequested(
  requsetActionCreator,
  failActionCreator,
  args
) {
  return {
    type: REFRESH_ACCESS_TOKEN_REQUESTED,
    requsetActionCreator,
    failActionCreator,
    args,
  };
}
// set normal status
export function setNormalStatus() {
  return { type: SET_NORMAL_STATUS };
}
// set login
export function setSoftLogin() {
  return { type: SET_SOFT_LOGIN };
}
// update user info
export function updateUserInfo(newInfo) {
  return { type: UPDATE_USER_INFO, newInfo };
}

// Sagas
/**
 *
 * @param {*} action
 * @returns
 */
function* checkEmail(action) {
  const { email, isForgot } = action;
  try {
    const { status, data } = yield call(ApiManager.checkEmail, email);

    const { result, error } = data;

    // XXX: 만약 서브 계정 등록 시 중복 이메일 체크로 해당 API 활용 되면 로직 수정 필요!!!
    // 이어서 RESET_PASSWORD_BY_EMAIL_REQUESTED 액션 실행 후 리턴

    if (isForgot) {
      // eslint-disable-next-line default-case
      switch (result.code) {
        case ResultCode.AUTH_STATUS.NORMAL:
        case ResultCode.AUTH_STATUS.DORMANT:
        case ResultCode.AUTH_STATUS.INITIAL_PASSWORD_REQUIRED:
          yield put(resetPasswordByEmailRequested(action.email, result.code));
      }
    }

    // code 1004: 존재하지 않는 계정; 미등록 이메일
    yield put(authCheckEmailSucceed(result));
  } catch (error) {
    yield put(authCheckEmailFailed(error));
  }
}

function* login(action) {
  try {
    const { status, data } = yield call(
      ApiManager.login,
      action.email,
      action.password
    );

    const { result, error } = data;

    const {
      tokenType,
      accessToken,
      refreshToken,
      email,
      username,
      ...restData
    } = result;
    // restData: expiresIn, tokenType, scope, code
    LocalStorageManager.setItem(LocalStorageKey.TOKEN_TYPE, tokenType);

    LocalStorageManager.setItem(LocalStorageKey.ACCESS_TOKEN, accessToken);
    LocalStorageManager.setItem(LocalStorageKey.REFESH_TOKEN, refreshToken);
    // yield put(authLoginSucceed(restData, { email, username }));
    // 로그인 성공 하면 사용자 정보 요청
    yield put(authReadMyInformationRequested(restData));
  } catch (error) {
    yield put(authLoginFailed(error));
  }
}

function* readMyInformation(action) {
  try {
    const { status, data } = yield call(ApiManager.readMyInformation);

    const { result, error } = data;

    yield put(authReadMyInformationSucceed(result, action.data));
  } catch (error) {
    yield put(authReadMyInformationFailed(error));
  }
}

/**
 * 로그아웃 API를 통해 서버에 저장된 사용자 Token 정보 삭제처리와 함께 클라이언트의 로그아웃 처리
 * 하려 했으나 클라이언트 로그아웃 처리만 적용!!!
 * TODO: saga를 거치지 않고 reducer에서 삭제 처리 하는것이 적절할 수 있음
 * @param {*} action
 * @returns
 */
function* logout(action) {
  try {
    LocalStorageManager.clear();

    yield put(authLogoutSucceed());
    setTimeout(() => (window.location.href = '/'), 100);
  } catch (error) {
    yield put(authLogoutFailed(error));
  }
}

/**
 * accessToken을 사용하는 모든 API의 saga 함수에서 401응답(UNAUTHORIZED)시 Token 재발행 처리 시도
 * 성공 및 실패 처리는 직전 401 실패한 API의 Action으로 위임 처리
 * 다른 API 에서 401 응답 => refreshToken으로 갱신 시도
 * accessToken 갱신 실패 => 임의 로그아웃 처리!!
 * 화면에서 관련된 메시지 제공 등 후속 조치 필요 + Logout Action 호출도...
 * @param {*} action
 * @returns
 */
function* refreshAccessToken(action) {
  try {
    const oldRefreshToken = LocalStorageManager.getItem(
      LocalStorageKey.REFESH_TOKEN
    );
    const { status, data } = yield call(
      ApiManager.refreshAccessToken,
      oldRefreshToken
    );
    const { result, error } = data;

    const { accessToken, refreshToken } = result;
    LocalStorageManager.setItem(LocalStorageKey.ACCESS_TOKEN, accessToken);
    LocalStorageManager.setItem(LocalStorageKey.REFESH_TOKEN, refreshToken);

    yield put(action.requsetActionCreator(...action.args));
  } catch (error) {
    // 임의 로그아웃 처리 적용
    LocalStorageManager.clear();
    yield put(
      action.failActionCreator({ status: StatusCode.UNAUTHORIZED, error })
    );
  }
}

export function* saga() {
  yield takeLatest(CHECK_EMAIL_REQUESTED, checkEmail);
  yield takeLatest(LOGIN_REQUESTED, login);
  yield takeLatest(READ_MY_INFORMATION_REQUESTED, readMyInformation);
  yield takeLatest(LOGOUT_REQUESTED, logout);
  yield takeLatest(REFRESH_ACCESS_TOKEN_REQUESTED, refreshAccessToken);
}
