import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Avatar from 'component/ui/image/Avatar';
import Menu from 'component/ui/menu/Menu';
import MenuItem from 'component/ui/menu/MenuItem';
import { ReactComponent as LogoMemoSmall } from 'static/image/logo-memo-small.svg';
import LocalStorageManager from 'manager/LocalStorageManager';
import LocalStorageKey from 'constant/LocalStorageKey';
import { getLocale } from 'util/Utility';

const Wrapper = styled.div`
  width: calc(100% - 44px);
  height: 50px;
  padding: 0 24px 0 20px;
  position: fixed;
  background-color: ${(props) => props.theme.color.WHITE};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 10px rgba(36, 38, 38, 0.05);
  z-index: 10;
`;

const Filler = styled.div`
  flex: 1;
`;

const LogoImage = styled(LogoMemoSmall)`
  cursor: pointer;
`;

const Divider = styled.div`
  margin-top: 4px;
  height: 1px;
  background-color: #e6eaec;
`;

const VersionText = styled.div`
  padding: 12px 16px;
  font-size: 9px;
  color: #8f979d;
`;

function Header(props) {
  const history = useHistory();

  const {
    // Redux state
    authState,
    // Redux dispatch
    showDialog,
    hideDialog,
    logout,
  } = props;

  const intl = useIntl();
  const location = useLocation();

  // 공지사항 dialog
  useEffect(() => {
    if (location.pathname !== '/' || location.search !== '') return;
    const showNoticeDialog = process.env.REACT_APP_SHOW_NOTICE_DIALOG || false;
    const currentDate = new Date();

    if (!JSON.parse(showNoticeDialog)) return;

    if (
      LocalStorageManager.getItem(
        LocalStorageKey.DO_NOT_SHOW_NOTICE_POPUP_TIL_THIS_DATE
      ) <= currentDate
    ) {
      showDialog('NoticeDialog', { showCloseIcon: true });
    }
  }, [location]);

  return (
    <Wrapper>
      <LogoImage
        onClick={() => {
          if (history.location.pathname === '/') {
            history.go(0);
          } else {
            history.push('/');
          }
        }}
      />
      <Filler />

      {authState.user && (
        <>
          <Avatar imgSrc={null} />
          <Menu
            style={{ marginLeft: 8 }}
            title={`${authState.user?.hospital?.hospitalName ?? ''} ${
              authState.user?.lastName
            }${authState.user?.firstName}${intl.locale == 'ko' ? '님' : ''}`}>
            <MenuItem
              title={intl.formatMessage({
                id: '02-Header-MenuItem-title-01',
                description: '내 계정',
                defaultMessage: '내 계정',
              })}
              onClick={() => {
                history.push('/my-account');
              }}
            />
            <MenuItem
              title={intl.formatMessage({
                id: '02-Header-MenuItem-title-02',
                description: '비밀번호 변경',
                defaultMessage: '비밀번호 변경',
              })}
              onClick={() => {
                history.push('/change-password');
              }}
            />
            {authState.isAdmin && (
              <MenuItem
                title={intl.formatMessage({
                  id: '02-Header-MenuItem-title-03',
                  description: '사용자 관리',
                  defaultMessage: '사용자 관리',
                })}
                onClick={() => {
                  history.push('/user-management');
                }}
              />
            )}
            <MenuItem
              title={intl.formatMessage({
                id: '02-Header-MenuItem-title-04',
                description: '약관 및 개인정보 보호',
                defaultMessage: '약관 및 개인정보 보호',
              })}
              onClick={() => {
                history.push('/privacy');
              }}
            />
            <MenuItem
              title={intl.formatMessage({
                id: '02-Header-MenuItem-title-05',
                description: '데이터로더 다운로드',
                defaultMessage: '데이터로더 다운로드',
              })}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_FILE_SERVER}/dataloader/${
                    process.env.REACT_APP_CUSTOM_ENV === 'prod'
                      ? 'prod'
                      : process.env.REACT_APP_CUSTOM_ENV === 'qa'
                      ? 'qa'
                      : 'dev'
                  }/${process.env.REACT_APP_LOADER_NAME}`
                );

                // alert(
                //   intl.formatMessage({
                //     id: '02-Header-MenuItem-title-06',
                //     description: '준비중입니다.',
                //     defaultMessage: '준비중입니다.',
                //   })
                // );
              }}
            />
            <MenuItem
              title={intl.formatMessage({
                id: '02-Header-MenuItem-title-07',
                description: '로그아웃',
                defaultMessage: '로그아웃',
              })}
              onClick={() => {
                logout();
              }}
            />

            <Divider />

            <VersionText>{`Version ${process.env.REACT_APP_VERSION}`}</VersionText>
          </Menu>
        </>
      )}
    </Wrapper>
  );
}

export default Header;
