import { defineMessages } from 'react-intl';

const Const = {
  CLIENT_NAME: {
    SYSTEM: 'system',
    WEB_APP: 'memo-web',
    PARTNER_WEB_APP: 'memo-partner-web',
  },

  // 단위 시간: 8 -> 8시간
  SESSION_DURATION_TIME: 8,

  PATIENT_SEX_LABEL_DICT: defineMessages({
    patientSex_M: {
      id: 'code_patientSex_M',
      description: '남자(성별)',
      defaultMessage: '남',
    },
    patientSex_F: {
      id: 'code_patientSex_F',
      description: '여자(성별)',
      defaultMessage: '여',
    },
  }),

  // Table header order type
  ORDER_TYPE: {
    NONE: 0,
    ASCENDING: 1,
    DESCENDING: 2,
    getNextOrderType: function (currentOrder) {
      switch (currentOrder) {
        case this.DESCENDING:
          return this.ASCENDING;
        case this.ASCENDING:
          return this.DESCENDING;
      }
    },
  },

  ECG_TEST_STATUS: {
    BEFORE_TEST: 10, // Before Test  # 시작 전
    PAIRING_DEVICE: 20, // Paring Device
    ON_GOING_TEST: 30, // On Going Test
    WAITING_RETURN: 40, // Wait for Return
    WAITING_UPLOAD: 50, // Wait for Uploading
    ECG_UPLOADED: 60, // ECG Data Uploaded
    UNDER_CLOUD: 70, // Under Cloud Analysis
    CONFIRMED_CLOUD: 80, // Confirmed cloud
    CUSTOMER_CONFIRMED: 200, // Customer Confirmed
    ERROR: 990, // Error'
    // DEPRECATED
    CALCULATING_TEST: 90,
    UNCALCULATED_TEST: 91,
    CALCULATED_TEST: 100,
    REPORT_RENDERING: 110,
    REPORT_RENDERED: 120,
  },

  ECG_TEST_STATUS_LABEL_DICT: defineMessages({
    // status 10, 20 둘 다 테이블에는 동일하게 [시작 전]으로 표기
    ecgTestStatus_10: {
      id: 'code_ecgTestStatus_10',
      description: '시작 전',
      defaultMessage: '시작 전',
    },
    ecgTestStatus_20: {
      id: 'code_ecgTestStatus_20',
      description: '시작 전',
      defaultMessage: '시작 전',
    },
    ecgTestStatus_30: {
      id: 'code_ecgTestStatus_30',
      description: '측정 중',
      defaultMessage: '측정 중',
    },
    ecgTestStatus_40: {
      id: 'code_ecgTestStatus_40',
      description: '반납 대기',
      defaultMessage: '반납 대기',
    },
    ecgTestStatus_50: {
      id: 'code_ecgTestStatus_50',
      description: '업로드 대기',
      defaultMessage: '업로드 대기',
    },
    ecgTestStatus_60: {
      id: 'code_ecgTestStatus_60',
      description: '업로드 완료',
      defaultMessage: '업로드 완료',
    },
    ecgTestStatus_70: {
      id: 'code_ecgTestStatus_70',
      description: '클라우드 분석 중',
      defaultMessage: '클라우드 분석 중',
    },
    ecgTestStatus_80: {
      id: 'code_ecgTestStatus_80',
      description: '클라우드 분석 완료',
      defaultMessage: '클라우드 분석 완료',
    },
    ecgTestStatus_90: {
      id: 'code_ecgTestStatus_90',
      description: '검사 계산 중',
      defaultMessage: '검사 계산 중',
    },
    ecgTestStatus_100: {
      id: 'code_ecgTestStatus_100',
      description: '검사 계산 완료',
      defaultMessage: '검사 계산 완료',
    },
    ecgTestStatus_110: {
      id: 'code_ecgTestStatus_110',
      description: '리포트 생성 중',
      defaultMessage: '리포트 생성 중',
    },
    ecgTestStatus_120: {
      id: 'code_ecgTestStatus_120',
      description: '리포트 생성 완료',
      defaultMessage: '리포트 생성 완료',
    },
    ecgTestStatus_200: {
      id: 'code_ecgTestStatus_200',
      description: '고객 확인 완료',
      defaultMessage: '고객 확인 완료',
    },
    ecgTestStatus_990: {
      id: 'code_ecgTestStatus_990',
      description: '오류',
      defaultMessage: '오류',
    },
  }),

  CLOUD_ANALYSIS_STATUS: {
    INIT: 10, // 'Init'  # '클라우드 분석 대기' 상태
    UNDER_AI: 20, // 'Under AI Analysis'  # '클라우드 분석 중' 상태
    UNDER_PARTNER: 30, // 'Under Partner Analysis'  # 편집 중' 상태
    PARTNER_EDIT_DONE: 60, //  # '편집 완료' 상태
    INTERNAL_EDIT_DONE: 200, // 'Confirmed Internal'  # 휴이노 내부 편집 완료
    ERROR: 990, // 'Error'
    // DEPRECATED
    REPORT_RENDERING: 40, //
    REPORT_RENDERED: 50, //
    UNDER_INTERNAL: 70, // 'Under Internal Analysis'  # 휴이노 내부 판독 중
  },

  // 리포트 생성 status
  REPORT_STATUS: {
    NO_REPORT: 10, // '생성 전'
    COMPUTING: 20, // '계산 중'
    COMPUTING_FAILED: 21, // '계산 실패'
    COMPUTED: 30, //
    REPORT_RENDERING: 40, // 'Rendering'
    REPORT_RENDERING_FAILED: 41, // 'Rendering Failed'
    REPORT_RENDERED: 50, // 'Rendered'
    REPORT_ERROR: 990, //, 'Error'
    isFailGeneratingReport: function (status) {
      return this.getFailGenerateingStatus().includes(status);
    },
    getFailGenerateingStatus: function () {
      return [this.COMPUTING_FAILED, this.REPORT_ERROR];
    },
  },

  DIAGNOSIS_TYPE: {
    NORMAL: 0,
    APC: 1,
    VPC: 2,
    AF: 3,
    OTHERS: 4,
    NOISE: 5,
    SVT: 6,
    VT: 7,
    AV_BLOCK: 8,
    PAUSE: 9,
    LEAD_OFF: -1,
  },

  CLASSIFICATION: {
    NORMAL: { value: 0, label: 'Normal', shortcut: 'Q' },
    APC: { value: 1, label: 'APC', shortcut: 'W' },
    VPC: { value: 2, label: 'VPC', shortcut: 'E' },
    NOISE: { value: 5, label: 'Noise', shortcut: 'R' },
    AV_BLOCK: { value: 8, label: 'AV Block', shortcut: 'T' },
    AF: { value: 3, label: 'AF', shortcut: 'A' },
    PAUSE: { value: 9, label: 'Pause', shortcut: 'S' },
    OTHERS: { value: 4, label: 'Others', shortcut: 'D' },
    SVT: { value: 6, label: 'SVT', shortcut: 'F' },
    VT: { value: 7, label: 'VT', shortcut: 'G' },
    ALL: { value: -1, label: 'All', shortcut: '' },
    LEAD_OFF: { value: -2, label: 'Lead Off', shortcut: '' },
  },

  PASSWORD_CHANGE_TYPE: {
    DEFAULT: 'default',
    FORGOT: 'forgot',
    DORMANT: 'release-dormant',
    NEW: 'new',
    RECOMMENDED: 'recommended',
  },

  ACCESS_LEVEL: defineMessages({
    READ_ONLY: {
      id: 'code_accessLevel_readOnly',
      description: '열람 전용',
      defaultMessage: '열람 전용',
      value: 1,
    },
    READ_WRITE: {
      id: 'code_accessLevel_readWrite',
      description: '수정 가능',
      defaultMessage: '수정 가능',
      value: 2,
    },
  }),

  STAFF_TYPE: defineMessages({
    DOCTOR: {
      id: 'code_staffType_doctor',
      description: '의사',
      defaultMessage: '의사',
      value: 1,
    },
    NURSE: {
      id: 'code_staffType_nurse',
      description: '간호사',
      defaultMessage: '간호사',
      value: 2,
    },
    MEDICAL_TECHNOLOGIST: {
      id: 'code_staffType_medicalTechnologist',
      description: '임상병리사',
      defaultMessage: '임상병리사',
      value: 3,
    },
    MEDICAL_STAFF: {
      id: 'code_staffType_medicalStaff',
      description: '직원',
      defaultMessage: '직원',
      value: 4,
    },
  }),

  /**
   * 병원 구분
   */
  USER_RELATION_TYPE: {
    INTERNAL: 10,
    PARTNER: 20,
    CUSTOMER: 30,
  },

  LANGUAGE: {
    EN: 'en',
    KO: 'ko',
  },

  EVENT_ITEM_FRAGMENT_CLASSNAME: 'event-item-wrap',

  PATIENT_EVENT_TYPE: {
    PALPITATION: 1, // '두근거림'
    NAUSEA: 2, // '울렁거림'
    HEADACHE: 3, // '두통'
    PLEURODYNIA: 4, // '흉통'
    DYSPNEA: 5, // '호흡 곤란'
    SLEEP: 6, // '취침'
    WAKEUP: 7, // '기상'
    PRESS_BUTTON: 1, //, '패치 버튼'
    ETC: -1, //, 'etc.'
  },
  PATIENT_EVENT_BY: {
    BUTTON: 1, // 'button'
    CHATBOT: 2, // 'chatbot'
  },
  PATIENT_EVENT_ENTRY_TYPE: {
    NOW: 1, // 'now'
    PAST: 2, // 'past'
  },

  /* 에러추적을 용이하게 하기 위한 Key Map */
  KEY_MAP: {
    ARROW_LEFT: 'ArrowLeft',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_UP: 'ArrowUp',
    ARROW_DOWN: 'ArrowDown',
    ENTER: 'Enter',
  },

  SIDE_PANEL: {
    TYPE: {
      SIDE_PANEL_SELECTED_TYPE: 'SIDE_PANEL_SELECTED_TYPE', // SidePanel 이벤트그룹에서 이벤트를 선택했을 경우
      CHART_SELECTED_TYPE: 'CHART_SELECTED_TYPE', // ECG차트에서 클릭했을 경우
      CHART_SECTION_TYPE: 'CHART_SECTION_TYPE', // ECG차트에서 구간을 선택했을경우
    },
  },
};

export const TAB_VALUES = [
  'general',
  'hr-review',
  'event-review',
  'beat-review',
];

export const ADD_REPORT_EVENT_STEP_MAP = {
  CANCEL: 0,
  SECTION: 1,
  TITLE: 2,
  SUBMIT: 3,
};

export default Const;
